import { Tooltip } from 'antd'
import React from 'react'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts'

export const DataGraphDaily = (dataReport) => {

    const dataGraphDaily = [
        {
            name: '0',
            uv: 4000,
            $: 2400,
            amt: 240,
        },
        {
            name: '2',
            uv: 3000,
            $: 1398,
            amt: 221,
        },
        {
            name: '4',
            uv: 2000,
            $: 9800,
            amt: 229,
        },
        {
            name: '8',
            uv: 2780,
            $: 3908,
            amt: 200,
        },
        {
            name: '10',
            uv: 1890,
            $: 4800,
            amt: 218,
        },
        {
            name: '12',
            uv: 2390,
            $: 3800,
            amt: 250,
        },
        {
            name: '14',
            uv: 3490,
            $: 4300,
            amt: 210,
        },
    ]

  return (
    <div>
        
        <ResponsiveContainer width="100%" height={200} className='mt-5'>
            <AreaChart
                width={500}
                height={200}
                data={dataGraphDaily}
                syncId="anyId"
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="$" stroke="#00a1ff" fill="#00a1ff" />
            </AreaChart>
        </ResponsiveContainer>

    </div>
  )
}
