import React, { useState } from 'react'
import {  message } from 'antd'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'
import { ConfirmDeleteWasher } from '../shared/ConfirmDeleteWasher'
import { useGetUserCardInfoQuery } from '../../services/user/user.service'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { EditWasherModal } from './EditWasherModal'
import { FaUser } from 'react-icons/fa'


export const WasherProfile = ({ washer,washerId }) => {
    
    const response = useGetUserCardInfoQuery(washerId)
    
    const { data, isLoading,isFetching } = response

    const cardInfo = data?.data
    
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
    const [showModal, setShowModal] = useState({
        editWasher: false,
        resetPassword: false,
        deleteWasher: false,
    })

    const showEditModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editWasher: true
        }))
    }

    const editModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editWasher: false
        }))
    }

    const handleEditWasherSubmit = (values) => {
    }

    const handleEditWasherFailed = () => {
        message.error('Submit failed!');
    }
  
    const showDeleteModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteWasher: true
        }))
    }

    const deleteModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteWasher: false
        }))
    }

    const handleDeleteWasherSubmit = (values) => {
    }

    return (
        <div className='flex flex-col w-full bg-white '>

        <ToastContainer />
            {
                showModal.editWasher &&
                <EditWasherModal
                    visible={showModal.editWasher}
                    handleCancel={editModalCancel}
                    handleSubmit={handleEditWasherSubmit}
                    handleFailed={handleEditWasherFailed}
                    washer={washer}
                />
            }
            {
                showModal.deleteWasher &&
                <ConfirmDeleteWasher
                    visible={showModal.deleteWasher}
                    onCancel={deleteModalCancel}
                    handleOk={handleDeleteWasherSubmit}
                    title="Delete Washer"
                    okText="Delete"
                    warningText="Are you sure to delete this client"
                    user={washer}
                />
                
            }

            <div className='justify-center p-3 py-7 nav-text'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                        <p>Personal Information</p>
                    </div>
                    <div className='flex px-3 items-center'>
                        <p
                            className='px-2 cursor-pointer'
                            onClick={showEditModal}
                        >
                            Edit
                        </p>
                        <p className='text-red-800 px-2 cursor-pointer'
                            onClick={showDeleteModal}
                        >
                            Delete
                        </p>
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                {
                    washer?.profilePicture?
                    <div className="relative justify-center">
                        <img className="w-64 h-64 rounded-full" src={washer?.profilePicture} alt={washer?.fullName} />
                    </div>
                    : <div className="relative w-1/2">
                        <FaUser className='w-64 h-64 rounded-full' />
                    </div>
                }
                </div>
                
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tbody>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    User ID
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.userID}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Full Name
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.fullName}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Email
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.email}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Phone Number
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.phoneNumber}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    State
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.address?.state}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Street
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.address?.street}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Zip Code
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.address?.zipCode}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    SSN/EIN
                                </th>
                                <td className="px-6 py-4">
                                    {washer?.address?.["SSN/EIN"]}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Created At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(washer?.createdAt).format('l')
                                    }
                                </td>
                            </tr>

                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Update At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(washer?.updatedAt).format('l')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='flex flex-row md:w-full items-center mt-3 flex-wrap'>
                        {
                            isLoading || isFetching ?
                            (<div className='flex'>
                                <Spin indicator={antIcon} />
                            </div>) :
                            (
                            cardInfo?.map(cardInfo => {
                                return (
                                    <div
                                        className='flex flex-col status_container mr-5 items-center mt-3 justify-center pt-5'>
                                        <h1 className='dashboard_header'>
                                            {cardInfo?.bankName}
                                        </h1>
                                        <p className='dashboard_items_value'>
                                            {cardInfo?.routingNumber}
                                        </p>
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


