
import React from 'react'
import { useGetUserProfileQuery } from '../../services/user/user.service';
import { Profile } from './Profile';

export const ProfileDetail = () => {
    
    const response = useGetUserProfileQuery()

    const { data} = response
    const user = data?.data

    return (
        <div>
            <Profile userData = {user} />
        </div>
    )
}
