
import React, { useEffect, useState } from 'react'


import { Modal, Button, Form, Input, message, Avatar, Upload } from 'antd'

import { AiOutlineUser, AiOutlinePlus } from 'react-icons/ai'
import {useAddWasherMutation} from '../../services/washer/washer.service'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import S3 from 'react-aws-s3';
import { Buffer } from 'buffer';


export const AddWasherModal = ({ visible, handleCancel }) => {
    window.Buffer = Buffer;

    const [profilePicture, setProfilePicture] = useState(null)

    const navigate = useNavigate()

    const [form] = Form.useForm();
    const [uploadPhotoErr,setUploadPhotoErr]=useState("")

    const [addWasher, { data,isLoading, isSuccess, isError, error }] = useAddWasherMutation()
    
    useEffect(() => {
        if (isSuccess && data?.success===true) {
            toast.success("Client Added successfully!",{toastId: "success"})
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }

        if (isSuccess && data?.code===400) {
            toast.error(data?.data)
            setTimeout(() => {
            }, 3000);
        }

        if (isError) {
            toast.error(error?.data.data)
            setTimeout(() => {
                navigate("/washer")
            }, 3000);
        }

    })


    const randomString = (length) => {
        var result = '';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const config = {
        bucketName: process.env.REACT_APP_BUCKET,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
        s3Url: process.env.REACT_APP_URL,
    }


    const handleSubmit = async (values) => {
            profilePicture ?
            setUploadPhotoErr("")
            :
            setUploadPhotoErr("please upload photo")

            const userRole = "WASHER"
        try {
            const randResult =  randomString(10)+ profilePicture.name

            const photoKey =  randResult

            const newFileName = photoKey;
            
            const ReactS3Client = new S3(config);
            
            ReactS3Client
            .uploadFile(profilePicture,newFileName)
            .then(data => data)
            .catch(err =>err)
            const img_url = "https://panda-file-store.s3.amazonaws.com/" + photoKey

            const valuesClient ={
                fullName: values.fullName,
                email: values.email,
                address: {
                    street: values.street,
                    state: values.state,
                    zipCode: values.zipCode,
                    "SSN/EIN": values?.["SSN/EIN"]
                },
                phoneNumber: values.phoneNumber,
            }

            const body = {
                ...valuesClient,
                userRole: userRole,
                password: "0000",
                profilePicture: img_url,
                fcm_token: randomString(10)
            }

            await addWasher(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        listType: 'picture',
        accept: '.png,.jpg,.jpeg,.doc,.pdf',
        beforeUpload: file => {
            setProfilePicture(file)
            return false
        },
    };

    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add Washer"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className=' xl:washer_modal_style lg:washer_modal_style md:washer_modal_style '
                confirmLoading={isLoading}
            >

                <div className=' mb-8 flex flex-col items-center justify-center'>
                    <div className='flex mb-2'>
                    {
                        profilePicture?.name?
                        <div className="relative">
                                <img className="w-64 h-64 rounded-full" src={URL.createObjectURL(profilePicture)} alt={profilePicture?.name} />
                            </div>
                            :
                        <Avatar
                            size={{
                                xs: 24,
                                sm: 32,
                                md: 40,
                                lg: 64,
                                xl: 80,
                                xxl: 100,
                            }}
                        >
                            <AiOutlineUser className='w-12 h-12 my-3' />
                        </Avatar>
                        }
                        <Upload {...props} showUploadList={false} >
                            <Button
                                className= 'mt-10 -ml-6 washer_upload_icon_test flex items-center justify-center'
                                >
                                <AiOutlinePlus className='w-6 h-6 -mx-2 rounded-md' type='upload' />
                            </Button>
                        </Upload>
                    </div>
                    {
                        profilePicture?
                        <p></p>
                        :
                        <p className='text-red-600'>{uploadPhotoErr}</p>
                    }    
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Full Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    
                    <Form.Item
                        label="Street"
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Street!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your State!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                        label="Zipcode"
                        name="zipCode"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Zipcode!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="SSN/EIN"
                        name="SSN/EIN"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your SSN/EIN!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Phone Number!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
