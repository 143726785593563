
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Tabs } from 'antd';
import { Home } from '../Home';
import { useNavigate, useParams } from 'react-router-dom';
import {  useGetClientsByIDQuery } from '../../services/clients/clients.service';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ClientsProfile } from './ClientsProfile';
import { ServiceHistory2 } from './serviceHistory/ServiceHistory2';
import { PaymentData } from './payments/PaymentData';
import { FaUser } from 'react-icons/fa';

export const ClientsDetail = () => {
    const { TabPane } = Tabs;
    const navigate = useNavigate()
    
    const { clientsId } = useParams()
    
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const reponse = useGetClientsByIDQuery(clientsId)
    const  {  data,isLoading, isSuccess, isFetching } = reponse

    const clients = data?.data 

    const callback = async (key) => {
    }

    const back = () => {
        navigate('/clients')
    }

    return (
        <Home>
            <div className='flex m-2 px-3 w-full'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
                {
                    isSuccess &&
                    <div className='flex items-center mx-3 px-3'>
                    {
                        clients?.profilePicture?
                        <div className="relative">
                            <img className="w-11 h-11 rounded-full" src={clients?.profilePicture} alt={clients?.fullName} />
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                        </div>
                    }
                        <p className='self-center mt-3 pl-2 text-md font-bold'>{clients?.fullName}</p>
                    </div>
                }
            </div>

            {
                isLoading || isFetching ?
                    (<div className='flex'>
                        <Spin indicator={antIcon} />
                    </div>) :
                    (
                    <div className='flex  flex-col '>  {/*lg:ml-40*/}
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Profile" key="1">
                                <ClientsProfile clients={clients} clientsId={clientsId}  />
                            </TabPane>
                            <TabPane tab="Service History" key="2">
                                <ServiceHistory2 />
                            </TabPane>
                            <TabPane tab="Payments" key="3">
                                <PaymentData />
                            </TabPane>
                        </Tabs>
                    </div>
                    )
            }
        </Home>
    )
}
