import { Tabs } from 'antd';
import React from 'react'
import { useGetCatagoryQuery } from '../../services/service/services.service';
import { Home } from '../Home'
import { Addtional } from './addtionalService/Additional';
import { Catagory } from './catagory/Catagory';
import { CatagoryService } from './serviceCatagory/CatagoryService';

export const Service = () => {
  const { TabPane } = Tabs;

  const response = useGetCatagoryQuery()
  const { data } = response
  const catagoryData = data?.data

  const callback = (key) => {
  }

  return (
    <Home>
          <Tabs defaultActiveKey="1" onChange={callback} className='w-full'>
              <TabPane tab="Catagory" key="1">
                <Catagory />
              </TabPane>
              
              <TabPane tab="Service" key="2">
                  <CatagoryService catagoryData={catagoryData}/>
              </TabPane>

              <TabPane tab="Addtional" key="3">
                  <Addtional catagoryData={catagoryData}/>
              </TabPane>

          </Tabs>
    </Home>
  )
}

