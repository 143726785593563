
import React, { useState } from 'react'
import '../clients/clients.css'
import { Input } from 'antd'
import moment from 'moment'
import { Home } from '../Home';
import { ClientsDataTable } from '../clients/ClientsDataTable';
import { useGetAllRecycleQuery } from '../../services/clients/clients.service'; 
import { FaUser } from 'react-icons/fa';


export const DeletedUser = () => {
    const { Search } = Input;

    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const [ setDataSort] = useState([]);
    const [order, setOrder] = useState("ACS");

    const response = useGetAllRecycleQuery()

    const { data, isError, isFetching,isLoading, isSuccess, error } = response

    const _data = data?.data

    const onSearch = (value) => {
        setSearchText(value)
         if(value !== '' ){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
            setDataSort(filteredData)
        }
        else{
            setFilteredResults(_data)
            setDataSort(_data)
        }
    }

    const Client = () =>{
        const value='CLIENT'
        setSearchText(value)
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const Washer = () =>{
        const value='WASHER'
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const Admin = () =>{
        const value='ADMIN'
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const All = () =>{
        setFilteredResults(_data)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
        {
            key: "userRole",
            title: "User Role",
            dataIndex: "userRole",
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("phoneNumber")
                  }
                };
            },  
        }, 
    {
        key: "fullName",
        title: "FullName",
        onHeaderCell: () => { return { onClick: () => { Sorting("fullName") } }},
        render: ( record) => (
            <div className='flex items-start  px-3'>
                {
                    record.isVerified ?
                        record?.profilePicture?
                        <div className="relative w-1/2">
                            <img className="w-11 h-11 rounded-full" src={record?.profilePicture} alt={record?.fullName} />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                        : 
                        record?.profilePicture?
                        <div className="relative w-1/2">
                            <img className="w-10 h-10 rounded-full" src={record?.profilePicture} alt="" />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-red-600 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-red-600 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                    
                }
                <p className='self-center  ml-3 pl-1 w-1/2'>{record.fullName}</p>
            </div>
        )
    },
    {
        key: "id",
        title: "Email",
        dataIndex: "id",
        onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},
    },
    {
        key: "phoneNumber",
        title: "Phone",
        dataIndex: "phoneNumber",
        onHeaderCell: () => { return { onClick: () => { Sorting("phoneNumber") } }},
    },
    {
        key: "createdAt",
        title: "Date Added",
        dataIndex: "createdAt",
        render: (createdAt) => ( <div> { moment(createdAt).format('L') } </div> ),
        onHeaderCell: () => { return { onClick: () => { Sorting("createdAt") } }},
    },
    {
        key: "lastLogIn",
        title: "Last log in",
        dataIndex: "updatedAt",
        render: (updatedAt) => ( <div> { moment(updatedAt).format('L') } </div> ),
        onHeaderCell: () => { return { onClick: () => { Sorting("updatedAt") } }},
    },
    ]

    
    return (
        <Home>

            <div className='flex flex-col'>
                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch}
                        />
                    </div>
                    <div className='w-1/2'>
                        <button className='bg-white shadow-md px-2 py-2' onClick={All}>
                            All   
                        </button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={Client}>
                            Client
                        </button> 
                        <button className='bg-white shadow-md px-2 py-2' onClick={Washer}>
                            Washer
                        </button> 
                        <button className='bg-white shadow-md px-2 py-2' onClick={Admin}>
                            Admin
                        </button> 
                    </div>


                </div>

                <ClientsDataTable
                    key={_data}
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </Home>
    )
}


