
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper, providesTagsHelperObject } from '../providesTagsHelper'


const tagType = "ADMIN"

export const adminUserApi = createApi({
    reducerPath: "adminUserApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAllAccessLevel: builder.query({
            query: () => '/accessLevel/all',
            providesTags: (result) => providesTagsHelper(result, tagType,"ADMIN"),            
        }),
        getAccessLevelById: builder.query({
            query: (adminId) => `accessLevel/byEmail/${adminId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"ADMIN"),            
        }),
        getAllAdminLog: builder.query({
            query: () => '/adminLog/all'
        }),
        getAllUserRole: builder.query({
            query: () => '/userRole/all',            
        }),
        getAllPayment: builder.query({
            // query: () => '/adminLog/byType/payment',
            query: (adminId) => `adminLog/byType/${adminId}`,

        }),
        getAllByRole: builder.query({
            query: (role) => `/user/byUserRole/${role}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "ADMIN","SUPER ADMIN", "CLIENT SERVICE", "WASHER"),
            invalidatesTags: [{ type: tagType },'ADMIN',"SUPER ADMIN", "CLIENT SERVICE", "WASHER"],
        }),
        getAllSuperAdmin: builder.query({
            query: () => '/users/byRole/super_admin',
        }),
        getAdminByID: builder.query({
            query: (adminId) => `user/profile/${adminId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "ADMIN"),
        }),
        getAdminLogByID: builder.query({
            query: (adminId) => `adminLog/byEmail/${adminId}`,
        }),
        getAdminLogDetailByID: builder.query({
            query: (adminId) => `adminLog/${adminId}`,
        }),
        addAdmin: builder.mutation({
            query: clientData => ({
                url: 'auth/signup',
                method: 'POST',
                body: clientData
            }),
            invalidatesTags: [{ type: tagType },'ADMIN'],
        }),
        addAdminRole: builder.mutation({
            query: adminData => ({
                url: 'userRole/add',
                method: 'POST',
                body: adminData
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        addAdminLog: builder.mutation({
            query: adminData => ({
                url: 'notification/send',
                method: 'POST',
                body: adminData
            }),
            invalidatesTags: [{ type: tagType },'ADMIN'],
        }),
        addAccessLevel: builder.mutation({
            query: adminData => ({
                url: 'accessLevel/add',
                method: 'POST',
                body: adminData
            }),
            invalidatesTags: [{ type: tagType },'ADMIN'],
        }),

        addNotifyUserRole: builder.mutation({
            query: adminData => ({
                url: 'notification/byRole',
                method: 'POST',
                body: adminData
            }),
            invalidatesTags: [{ type: tagType }],
        }),

        updateProfile: builder.mutation({
            query: body => ({
                url: `user/updateProfile/${body.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: tagType },'WASHER', 'CLIENT', 'ADMIN'],
        }),
        deleteUser: builder.mutation({
            query: adminId => ({
                url: `user/removeUser/${adminId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'ADMIN' },'ADMIN'],
        }),
        addVerifyUser: builder.mutation({
            query: userData => ({
                url: 'auth/verify',
                method: 'POST',
                body: userData
            }),
            invalidatesTags: [{ type: tagType } , 'ADMIN'],
        }),
        getUserProfile: builder.query({
            query: () => 'user/profile',
            providesTags: (result) => providesTagsHelperObject(result, tagType,"USER", "ADMIN"),
        }),
        getUserProfileByID: builder.query({
            query: (adminId) => `user/profile/${adminId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "USER", "ADMIN"),
        }),

    })
})


export const { 
    useGetAllAdminLogQuery,
    useGetAllAccessLevelQuery,
    useGetAccessLevelByIdQuery,
    useGetAllUserRoleQuery,
    useGetAllPaymentQuery,
    useGetAllByRoleQuery,
    useGetAllSuperAdminQuery, 
    useGetAdminByIDQuery,
    useGetAdminLogByIDQuery,
    useGetAdminLogDetailByIDQuery,
    useAddAdminMutation,
    useAddAdminLogMutation,
    useAddAccessLevelMutation,
    useAddNotifyUserRoleMutation,
    useAddAdminRoleMutation,
    useUpdateProfileMutation,
    useDeleteUserMutation,
    useAddVerifyUserMutation,
    useGetUserProfileQuery,
    useGetUserProfileByIDQuery,

} = adminUserApi



