import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {API_BASE_URL} from '../../common'
import {token} from '../auth-header'
import {providesTagsHelper, providesTagsHelperObject} from '../providesTagsHelper'

const tagType = "USER"

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    tagTypes: [tagType, "CLIENT", "WASHER"],
    endpoints: (builder) => ({
        getClient: builder.query({
            query: () => 'users/byRole/client',
            providesTags: (result) => providesTagsHelper(result, tagType, "admin"),
        }),
        getUser: builder.query({
            query: () => '/admin/report/users',
            providesTags: (result) => providesTagsHelper(result, tagType, "admin"),
        }),
        getWasher: builder.query({
            query: () => 'users/byRole/washer',
            providesTags: (result) => providesTagsHelper(result, tagType, "admin"),
        }),
        getAdmins: builder.query({
            query: () => '/users/byRole/admin',
            providesTags: (result) => providesTagsHelper(result, tagType, "ADMIN"),
        }),
        getSuperAdmins: builder.query({
            query: () => '/users/byRole/washer',
            providesTags: (result) => providesTagsHelper(result, tagType, "WASHER"),
        }),
        getUserByID: builder.query({
            query: (userID) => `user/${userID}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "CLIENT"),
        }),
        getDeleteUserByID: builder.query({
            query: (userID) => `user/${userID}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "USER"),
        }),
        getUserProfile: builder.query({
            query: () => 'user/profile',
            providesTags: (result) => providesTagsHelperObject(result, tagType,"USER", "ADMIN"),
        }),
        getUserProfileByID: builder.query({
            query: (adminId) => `user/profile/${adminId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "USER", "ADMIN"),
        }),
        getUserCardInfo: builder.query({
            query: (clientsId) => `card/get_bank_account/${clientsId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "ADMIN"),
        }),
        changePassword: builder.mutation({
            query: body => ({
                url: 'auth/changePassword',
                method: 'PATCH',
                body: {...body.body}
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'CLIENT' }],
        }),
        deleteUser: builder.mutation({
            query: clientsId => ({
                url: `auth/removeUser/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'ADMIN' }, { type: 'CLIENT' }],
        }),
        deleteAdminLog: builder.mutation({
            query: adminId => ({
                url: `adminLog/${adminId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'CLIENT' }],
        }),
        updateProfile: builder.mutation({
            query: body => ({
                url: `user/updateProfile/${body.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'USER' }],
        }),
        updateAdminLog: builder.mutation({
            query: body => ({
                url: `adminLog/${body.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: ['CLIENT', 'WASHER','User'],
        }),

        ////////////////////////////
        addRefund: builder.mutation({
            query: refund => ({
                url: 'refundCharge/all',
                method: 'POST',
                body: refund
            }),
            invalidatesTags: [{ type: tagType },'USER'],
        }),
        getRefund: builder.query({
            query: () => 'refundCharge/all',
            providesTags: (result) => providesTagsHelper(result, tagType, "admin"),
        }),
        getRefundByID: builder.query({
            query: (refundId) => `refundCharge/byEmail/${refundId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "WASHER"),
        }),
        addVerifyUser: builder.mutation({
            query: userData => ({
                url: 'auth/verify',
                method: 'POST',
                body: userData
            }),
            invalidatesTags: [{ type: tagType } ,{type:"CLIENT"}, {type:"WASHER"}],
        }),

        addSendOTP: builder.mutation({
            query: userData => ({
                url: 'auth/sendOTP',
                method: 'POST',
                body: userData
            }),
            invalidatesTags: [{ type: tagType }],
        })


    }),


})


export const {
    useGetClientQuery,
    useGetUserQuery,
    useDeleteUserByIDQuery,
    useGetWasherQuery,
    useGetUserByIDQuery,
    useGetUserProfileQuery,
    useGetUserProfileByIDQuery,
    useGetUserCardInfoQuery,
    useChangePasswordMutation,
    useResetPasswordMutation,
    useDeleteUserMutation,
    useDeleteAdminLogMutation,
    useUpdateProfileMutation,
    useUpdateAdminLogMutation,
    useGetAdminsQuery,
    useGetSuperAdminsQuery,
    ///////////////////////
    useGetRefundQuery,
    useAddRefundMutation,
    useGetRefundByIDQuery,
    useAddVerifyUserMutation,
    useAddSendOTPMutation,
    useAddAutomaticNotificationMutation,
    useAddScheduledNotificationMutation,
    useAddTeamMemberMutation
} = usersApi




