
import React, { useState } from 'react'

import { Modal, Button, Form, Input, message, Select, TreeSelect } from 'antd'

import {useAddAccessLevelMutation, useGetAllByRoleQuery} from '../../../services/admin/admin.service'
import { toast, ToastContainer } from 'react-toastify'


export const AddAccessLevel = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();
    const [view, setIsView]=useState(true)
    const [edit, setIsEdit]=useState(true)
    const [deleteuser, setIsDelete]=useState(true)
    const [role,setRole]= useState("ADMIN")

    const [valueSelect, setValueSelect] = useState("")

    const response = useGetAllByRoleQuery(role)
    const { data } = response
    const _data = data?.data?.Items

    const [addAccessLevel, {  isLoading, isSuccess, isError, error }] = useAddAccessLevelMutation()

    if (isSuccess) {
            toast.success("Acesse Level Added successfully!",{toastId: "success"})
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
    if (isError) {
        toast.error(error?.data.data)
        setTimeout(() => {
        }, 3000);
    }


    const handleSubmit = async (values) => {
        try {
                const AdminLog={
                    email: valueSelect,
                    title: values.title,
                    description: values.description,
                    isEdit : edit,
                    isView : view,
                    isDelete : deleteuser
                }

                const body = {
                    ...AdminLog
                }

                await addAccessLevel(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

   
    const handleIsView = (values) =>{
        if(values==='true'){
            setIsView(true)
        }
        if(values==='false'){
            setIsView(false)
        }
    }

    const handleIsDelete = (values) =>{
        if(values==='true'){
            setIsDelete(true)
        }
        if(values==='false'){
            setIsDelete(false)
        }
    }

    const handleIsEdit = (values) =>{
        if(values==='true'){
            setIsEdit(true)
        }
        if(values==='false'){
            setIsEdit(false)
        }
    }

    const onChange = (newValue) => {
        setValueSelect(newValue);
    };

    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style '
                confirmLoading={isLoading}
            >

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <div >User Role</div>
                    <div className="relative w-full lg:max-w-sm  border-white justify-center">
                        <select  value={role} onChange={(event) => setRole(event.target.value)} className="w-full p-2.5  bg-white  rounded-md ">
                            <option value="ADMIN">Admin</option>
                            <option value="SUPERADMIN">Super Admin</option>
                            <option value="CLIENTSERVICE">Clients service</option>
                            <option value="WASHERSERVICE">Washer service</option>
                        </select>
                    </div>

                <div>Full Name</div>
                <div className='w-full flex '>
                        <TreeSelect
                            style={{
                                width: '100%',
                            }}
                            value={valueSelect}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={_data?.map( (data,index) => ({
                                    title : data?.fullName,
                                    value : data?.id,
                                })
                            )}
                            placeholder="Please select"
                            treeDefaultExpandAll
                            onChange={onChange}
                        />
                    </div>

                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item label="Edit" name="isEdit"  >
                        <Select onChange={handleIsEdit}>
                            <Select.Option value="true">True</Select.Option>
                            <Select.Option value="false">False</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Delete" name="isDelete"  >
                        <Select onChange={handleIsDelete}>
                            <Select.Option value="true">True</Select.Option>
                            <Select.Option value="false">False</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="View" name="isView"  >
                        <Select onChange={handleIsView}>
                            <Select.Option value="true">True</Select.Option>
                            <Select.Option value="false">False</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
