
import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../features/authSlice'
import { authApi } from '../services/authApi'
import { clientsUserApi } from '../services/clients/clients.service'
import { washerUserApi } from '../services/washer/washer.service'
import { adminUserApi } from '../services/admin/admin.service'
import { settingApi } from '../services/settings/setting.service'
import { subscrptionUserApi } from '../services/subscrption/subscrption.service'
import { usersApi } from '../services/user/user.service'
import { serviceUserApi } from '../services/service/services.service'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [clientsUserApi.reducerPath] : clientsUserApi.reducer,
        [washerUserApi.reducerPath] : washerUserApi.reducer,
        [adminUserApi.reducerPath] : adminUserApi.reducer,
        [serviceUserApi.reducerPath] : serviceUserApi.reducer,
        [subscrptionUserApi.reducerPath] : subscrptionUserApi.reducer,
        [settingApi.reducerPath]: settingApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        usersApi.middleware,
        authApi.middleware,
        clientsUserApi.middleware,
        washerUserApi.middleware,
        adminUserApi.middleware,
        serviceUserApi.middleware,
        settingApi.middleware,
        subscrptionUserApi.middleware
    ])
})


// setupListeners(store.dispatch)


