import React, { useState } from 'react'

import { Button, Spin } from 'antd'
import { Home } from '../Home'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import UpdateProfile from './UpdateProfile'
import { LoadingOutlined } from '@ant-design/icons';
import { useGetUserProfileByIDQuery } from '../../services/admin/admin.service';

export const Profile = ( userData) => {

    const adminId = userData?.userData?.email

    const [visible, setVisible] = useState(false)

    const response = useGetUserProfileByIDQuery(adminId)
    const navigate = useNavigate()

    const { data, isError, isFetching, isLoading, isSuccess, error } = response
    const user = data?.data

    const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
    const back = () => {
        navigate('/dashboard')
    }
    const updateProfile = (user) => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const RenderProfiles = () => {
         if (user?.userRole === 'ADMIN' || user?.userRole === 'CLIENT SERVICE' || user?.userRole === 'WASHER SERVICE' || user?.userRole === 'SERVICE MANAGER' || user?.userRole === 'CLIENT' ) {
            return (
                <>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center'>
                            {
                                <div className="relative">
                                    <img className="w-64 h-64 rounded-full" src={user?.profilePicture} alt={user?.fullName} />
                                </div>
                            }
                        </div>
                        <div className='flex p-3'>
                            <div className='w-1/2'>
                                <p className=''>Full Name</p>
                                <p className=''>Email</p>
                                <p className=''>Role</p>
                                <p className=''>Phone Number</p>
                                <p className=''>State</p>
                                <p className=''>Street</p>
                                <p className=''>Zip Code</p>
                                <p className=''>SSN/EIN</p>
                                <p className=''>Registered At</p>
                            </div>
                            <div className='w-1/2'>
                                <p className=''>{user?.fullName}</p>
                                <p className=''>{user?.email}</p>
                                <p className=''>{user?.userRole}</p>
                                <p className=''>{user?.phoneNumber}</p>
                                <p className=''>{user?.address?.state}</p>
                                <p className=''>{user?.address?.street}</p>
                                <p className=''>{user?.address?.zipCode}</p>
                                <p className=''>{user?.address?.["SSN/EIN"]}</p>
                                <p className=''>{moment(user?.createdAt).format('L')}</p>
                        
                            </div>
                        </div>
                    </div>

                </>
            )
        }

    }



    return (
        <Home>
            <div>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
            </div>
            <div className='flex items-center justify-center w-full p-5'>

                {
                    visible &&
                    <UpdateProfile
                        visible={visible}
                        handleCancel={handleCancel}
                        user={user}
                    />
                }

                {
                    isError &&
                    <div className='flex mt-3'>
                        <p className='text-red-500 text-md font-bold mx-3'>
                            {error?.name || error?.status}
                        </p>
                        <p className='text-red-500 text-md font-bold'>
                            {error?.message || error?.data.message}
                        </p>
                    </div>
                }

                <div className='md:w-1/2 w-full shadow-xl bg-white p-5'>


                    {
                        isLoading || isFetching ? (<Spin indicator={antIcon} />) :

                            isSuccess &&
                            <>
                                <RenderProfiles />

                                <Button
                                    className='w-full bg-green-600'
                                    onClick={() => updateProfile(user)}
                                >
                                    Change Profile
                                </Button>
                            </>
                    }


                </div>
            </div>
        </Home>
    )
}
