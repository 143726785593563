
import React, { useEffect, useState } from 'react'

import './washer.css'
import {  Input } from 'antd';
import moment from 'moment'
import { userTypes } from '../../common/UserType';
import { WasherOptions } from './WasherOptions';
import { Home } from '../Home';
import { WasherDataTable } from './WasherDataTable';
import { useGetAllWasherQuery } from '../../services/washer/washer.service';
import { AddWasherModal } from './AddWasherModal';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import ReactGA from 'react-ga';


export const Washer = () => {
    const { Search } = Input;

    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState("ACS");
    const [ setShowEditModal] = useState(false)
    const [ setUserId] = useState('')
    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);

    const response = useGetAllWasherQuery()

    const { data, isError, isFetching, isLoading, isSuccess, error } = response

    const _data = data?.data?.Items
    const navigate=useNavigate()

    useEffect(()=>{
        ReactGA.initialize('G-2BLMYKFBGZ');
        ReactGA.pageview(window.location.pathname + window.location.search);
    })

    const onSearch = (value) => {
        if(value !== ''){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(_data)
        }
    }

    const Active = () =>{
        const value="true"
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const InActive = () =>{
        const value="false"
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const All = () =>{
        setFilteredResults(_data)
    }

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleEditModal = (id) => {
        setShowEditModal(true)
        setUserId(id)
    }
    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }
    
    const columns = [
        {
            key: "fullName",
            title: "fullName",
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            onHeaderCell: () => { return { onClick: () => { Sorting("fullName") } }},    
            render: ( record) => (
                <div className='flex items-start  px-3'>
                    {
                    record.isVerified ?
                        record?.profilePicture?
                        <div className="relative w-1/2">
                            <img className="w-11 h-11 rounded-full" src={record?.profilePicture} alt={record?.fullName} />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                    
                        : 
                        record?.profilePicture?
                        <div className="relative w-1/2">
                            <img className="w-10 h-10 rounded-full" src={record?.profilePicture} alt="" />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-red-600 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-red-600 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div>
                    }
                    <p className='self-center  ml-3 pl-1 w-1/2'>{record.fullName}</p>
                </div>
            )
        },
        {
            key: "id",
            title: "Email",
            dataIndex: "id",
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},    
        },
        {
            key: "phoneNumber",
            title: "Phone",
            dataIndex: "phoneNumber",
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            onHeaderCell: () => { return { onClick: () => { Sorting("phoneNumber") } }},        
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => ( <div> { moment(createdAt).format('L') } </div> ),
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            onHeaderCell: () => { return { onClick: () => { Sorting("createdAt") } }},    
        },
        {
            key: "lastLogIn",
            title: "Last log in",
            dataIndex: "updatedAt",
            render: (updatedAt) => ( <div> { moment(updatedAt).format('L') } </div> ),
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            onHeaderCell: () => { return { onClick: () => { Sorting("updatedAt") } }},    
        },
        {
            key: "userID",
            title: "Action",
            render: (record) => <WasherOptions
                record={record}
                handleEditModal={handleEditModal}
                userType={userTypes.WASHER}
            />
            
        },
    ]

    return (
        <Home>

            <div className='flex flex-col'>
                {
                    visible && <AddWasherModal visible={visible} handleCancel={handleCancel} />
                }

                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch} />
                    </div>
                    <div>
                        <button className=' bg-white shadow-md px-2 py-2' onClick={All}>
                            All   
                        </button>
                        <button className=' bg-white shadow-md px-2 py-2' onClick={Active}>
                            Active
                        </button> 
                        <button className=' bg-white shadow-md px-2 py-2' onClick={InActive}>
                            InActive
                        </button> 
                    </div>

                    <div className=' mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add
                        </button>
                    </div>
                </div>

                <WasherDataTable
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </Home>
    )
}
