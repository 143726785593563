import React, {useState} from 'react'
import {message} from 'antd'
import moment from 'moment'
import {ConfirmDeleteUser} from '../shared'
import {EditAdminModal} from './EditAdminModal'
import {ToastContainer} from 'react-toastify'
import { FaUser } from 'react-icons/fa'

export const AdminProfile = ({ admin }) => {
    const [showModal, setShowModal] = useState({
        editAdmin: false,
        resetPassword: false,
        deleteAdmin: false,
    })

    const showEditModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editAdmin: true
        }))
    }

    const editModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editAdmin: false
        }))
    }

    const handleEditAdminSubmit = (values) => {
    }

    const handleEditAdminFailed = () => {
        message.error('Submit failed!');
    }


    const showDeleteModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteAdmin:  true
        }))
    }

    const deleteModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteAdmin: false
        }))
    }

    const handleDeleteAdminSubmit = (values) => {
    }

    return (
        <div className='flex flex-col w-full bg-white'>
        <ToastContainer />
            {
                showModal.editAdmin &&
                <EditAdminModal
                    visible={showModal.editAdmin}
                    handleCancel={editModalCancel}
                    handleSubmit={handleEditAdminSubmit}
                    handleFailed={handleEditAdminFailed}
                    admin={admin}
                />
            }
            {
                showModal.deleteAdmin &&
                <ConfirmDeleteUser
                    visible={showModal.deleteAdmin}
                    onCancel={deleteModalCancel}
                    handleOk={handleDeleteAdminSubmit}
                    title="Delete clients"
                    okText="Delete"
                    warningText="Are you sure to delete this clients"
                    user={admin}
                />
            }

            <div className=' w-full p-3 py-7'>
            <div className='justify-center p-3 py-7 nav-text'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                        <p>Personal Information</p>
                    </div>
                    <div className='flex px-3 items-center'>
                        <p
                            className='px-2 cursor-pointer'
                            onClick={showEditModal}
                        >
                            Edit
                        </p>
                        <p className='text-red-800 px-2 cursor-pointer'
                            onClick={showDeleteModal}
                        >
                            Delete
                        </p>
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                {
                    admin?.profilePicture?
                    <div className="relative">
                        <img className="w-64 h-64 rounded-full" src={admin?.profilePicture} alt={admin?.fullName} />
                    </div>
                    : <div className="relative w-1/2">
                        <FaUser className='w-64 h-64 rounded-full' />
                    </div>
                }
                </div>
                
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tbody>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    User ID
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.userID}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Full Name
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.fullName}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Email
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.email}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Phone Number
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.phoneNumber}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    State
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.address?.state}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Street
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.address?.street}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Zip Code
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.address?.zipCode}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    State
                                </th>
                                <td className="px-6 py-4">
                                    {admin?.address?.["SSN/EIN"]}
                                </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Created At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(admin?.createdAt).format('l')
                                    }
                                </td>
                            </tr>

                            <tr className="bg-white border-b  ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Update At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(admin?.updatedAt).format('l')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>
    )
}


