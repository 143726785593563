
import React, { useState } from 'react'

import {  Input } from 'antd'
import { AdminDataTable } from '../../admin/AdminDataTable';
import moment from 'moment'
import { useParams } from 'react-router-dom';
import { useGetServiceByEmailQuery } from '../../../services/service/services.service';

export const PaymentData = () => {
    const { Search } = Input;

    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const [order, setOrder] = useState("ACS");

    const {washerId} = useParams()

    const response = useGetServiceByEmailQuery(washerId)

    const { data ,isLoading, isFetching, isSuccess, isError, error} = response

    const _data = data?.data?.Items

    const onSearch = (value) => {
        setSearchText("")
        if(value !== ''){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(_data)
        }
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
        {
            key: "isPayed",
            title: "Is Payed",
            dataIndex: ["paymentMethodDetail","isPayed"],
            render: (record) => record ? <p>Yes</p> : <p>No</p> 
        },
        {
            key: "isCash",
            title: "Is Cash",
            dataIndex: ["paymentMethodDetail","isCash"],
            render: (record) => record ? <p>Yes</p> : <p>No</p> 
        },
        {
            key: "price",
            title: "Price",
            dataIndex: ["paymentMethodDetail","price", "itemCost"],
        },
        {
            key: "tax",
            title: "Tax",
            dataIndex: ["paymentMethodDetail","price", "tax"],
        },
        {
            key: "tip",
            title: "Tip",
            dataIndex: ["paymentMethodDetail","price", "tip"],
        },
        {
            key: "cardID",
            title: "Client",
            dataIndex: ["clientID"],
        },
        {
            key: "createdAt",
            title: "Created At",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("createdAt")
                  }
                };
            },
        },
        {
            key: "updatedAt",
            title: "updated At",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("createdAt")
                  }
                };
            },
        },
    ]

    
 
    return (
            <div className='flex flex-col'>
                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch} />
                    </div>
                    <div className='flex flrx-row'>
                    </div>
                </div>

                <AdminDataTable
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
            
    )
}

