import React from 'react'
import {
    ChangePassword,
    Clients,
    ClientsDetail,
    Washer,
    WasherDetail,
    Admin,
    AdminDetail,
    Dashboard,
    Login,
    AdminLogAll,
    DeletedUser,
    Service
} from '../components';

import {
    Routes,
    Route,
} from "react-router-dom";


import { ProtectedRoute } from './ProtectedRoute';
import { PageNotFound } from './PageNotFound';
import { Subscription } from '../components/subscription/Subscription';
import { SubscriptionDetail } from '../components/subscription/SubscriptionDetail';
import { Addtional } from '../components/service/addtionalService/Additional';
import { ServiceHistoryDetail } from '../components/clients/serviceHistory/ServiceHistoryDetail';
import { AccessLevelAll } from '../components/admin/accessLevel/AccessLevelAll';
import { ProfileDetail } from '../components/profile/ProfileDetail';

export const MainRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />}></Route>

                <Route element={<ProtectedRoute />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path="/clients" element={<Clients />}></Route>
                    <Route path="/washer" element={<Washer />}></Route>
                    <Route path="/user" element={<Admin />}></Route>
                    <Route path="/clients/detail/:clientsId" className="nav-text" element={<ClientsDetail />}></Route>
                    <Route path="/clients/detail/:clientsId/detail/:serviceHistoryId"  element={<ServiceHistoryDetail />}></Route>
                    <Route path="/washer/detail/:washerId" element={<WasherDetail />}></Route>
                    <Route path="/user/detail/:adminId" element={<AdminDetail />}></Route>
                    <Route path="/service/detail/:serviceId" element={<Addtional />}></Route>
                    <Route path="/service" element={<Service />}></Route>
                    <Route path="/admin/adminLog" element={<AdminLogAll />}></Route>
                    <Route path="/admin/accessLevel" element={<AccessLevelAll />}></Route>
                    <Route path="/profile" element={<ProfileDetail />}></Route>
                    <Route path="/deletedUser" element={<DeletedUser />}></Route>
                    <Route path="/subscription" element={<Subscription />}></Route>
                    <Route path="/subscription/detail/:SubscriptionId" element={<SubscriptionDetail />}></Route>
                    <Route path="/change-password" element={<ChangePassword />}></Route>
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    )
}
