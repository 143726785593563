
import React, { useState } from 'react'
import { Input } from 'antd'
import moment from 'moment'
import { userTypes } from '../../../common/UserType';
import { ClientsDataTable } from '../../clients/ClientsDataTable';
import { useGetCatagoryQuery } from '../../../services/service/services.service';
import { AddCatagory } from './AddCatagory';
import { CatagoryOption } from './CatagoryOption';


export const Catagory = () => {
    const { Search } = Input;

    const [visible, setVisible] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const [ setDataSort] = useState([]);
    const [order, setOrder] = useState("ACS");
    const [ setShowEditModal] = useState(false)
    const [ setUserId] = useState('')

    const response = useGetCatagoryQuery()

    const { data, isError, isFetching,isLoading, isSuccess, error } = response

    const _data = data?.data

    const onSearch = (value) => {
        setSearchText(value)
         if(value !== '' ){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
            setDataSort(filteredData)
        }
        else{
            setFilteredResults(_data)
            setDataSort(_data)
        }
    }
   
    const handleEditModal = (id) => {
        setShowEditModal(true)
        setUserId(id)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
    {
        key: "name",
        title: "Name",
        dataIndex: "name",
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("name")
                }
            };
        },
    }, 
    {
        key: "description",
        title: "Description",
        dataIndex: "description",
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("description")
                }
            };
        },
    },
    {
        key: "createdBy",
        title: "Created By",
        dataIndex: "createdBy",
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("createdBy")
                }
            };
        },
    },
    {
        key: "createdAt",
        title: "Date Added",
        dataIndex: "createdAt",
        render: (createdAt) => (
            <div>
                {
                    moment(createdAt).format('L')
                }
            </div>
        ),
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("createdAt")
                }
            };
        },
    },
 
    {
        key: "userID",
        title: "Action",
        render: (record) => 
        <div>
            <CatagoryOption
                record={record}
                handleEditModal={handleEditModal}
                userType={userTypes.CLIENTS}
            />
        </div>
    },
    ]


    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }
    
    return (
        <>
            <div className='flex flex-col w-full'>
                {
                   visible && <AddCatagory visible={visible} handleCancel={handleCancel}/>
                }
                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch}
                        />
                    </div>
                    <div className='mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add Catagory
                        </button>
                    </div>
                </div>
                <div className='w-full '>
                <ClientsDataTable
                    key={_data}
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
                </div>
            </div>
        </>
    )
}
