
import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { EditAdminModal } from './EditAdminModal';
import { toast } from 'react-toastify';

export const AdminDataTable = ({_data,columns, isLoading, isError, isFetching, error }) => {

    const [showEditModal, setShowEditModal] = useState(false)
    const [userId] = useState('')

    useEffect(()=>{
        if(isError){
            toast.error("unable to load data")
        }
    })
    
    const cancelEditModal = () => {
        setShowEditModal(false)
    }

    return (
        <div className='flex flex-col'>
            {
                showEditModal && <EditAdminModal showEditModal={showEditModal} cancelEditModal={cancelEditModal} userId={userId} />
            }

            <div className='mt-8 w-full overflow-x-auto'>
                <Table
                    dataSource={_data}
                    columns={columns}
                    pagination={true}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}
