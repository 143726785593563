
import React, { useState } from 'react'

import {  Input } from 'antd';
import moment from 'moment'
import { Home } from '../Home';
import { SubscrptionDataTable } from './SubscrptionDataTable';
import { useGetAllSubscrptionQuery } from '../../services/subscrption/subscrption.service';
import { AddSubscrptionModal } from './AddSubscrptionModal'; 


export const Subscription = () => {
    const { Search } = Input;

    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState("ACS");
    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const response = useGetAllSubscrptionQuery()

    const { data, isError, isFetching, isLoading, isSuccess, error } = response

    const _data = data?.data
    
    const onSearch = (value) => {
        setSearchText(value)
        if(value !== ''){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(_data)
        }
    }

    const Active = () =>{
        const value="Life Time"
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const InActive = () =>{
        const value="Weekly"
        setSearchText(value)
        const filteredData = _data.filter((item) => {
            return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
        })
        setFilteredResults(filteredData)
    }

    const All = () =>{
        setFilteredResults(_data)
    }

    const showModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
        {
            key: "subTitle",
            title: "SubTitle",
            dataIndex: "subTitle",
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("subTitle")
                    }
                };
            },            
        },
        {
            key: "price",
            title: "Price",
            dataIndex: "price",
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("price")
                    }
                };
            },
        },
        {
            key: "description",
            title: "Description",
            dataIndex: "description",
        },
        {
            key: "duration",
            title: "Duration",
            dataIndex: "duration",
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("duration")
                    }
                };
            },     
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("createdAt")
                    }
                };
            },
        },
        {
            key: "lastLogIn",
            title: "Last log in",
            dataIndex: "updatedAt",
            render: (updatedAt) => (
                <div>
                    {
                        moment(updatedAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("updatedAt")
                    }
                };
            },
        },
    ]

    return (
        <Home>

            <div className='flex flex-col'>
                {
                    visible && <AddSubscrptionModal visible={visible} handleCancel={handleCancel} />
                }

                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch} />
                    </div>
                    <div>
                        <button className=' bg-white shadow-md px-2 py-2' onClick={All}>
                            All   
                        </button>
                        <button className=' bg-white shadow-md px-2 py-2' onClick={Active}>
                            Life Time
                        </button> 
                        <button className=' bg-white shadow-md px-2 py-2' onClick={InActive}>
                            Weekly
                        </button> 
                    </div>

                    <div className=' mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add
                        </button>
                    </div>
                </div>

                <SubscrptionDataTable
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </Home>
    )
}
