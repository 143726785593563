import { Input } from 'antd';
import React, { useState } from 'react'
import moment from 'moment'
import { useGetAddtionalServiceQuery } from '../../../services/service/services.service'; 
import { ClientsDataTable } from '../../clients/ClientsDataTable'; 
import { AddAddtional } from './AddAddtional';
import { userTypes } from '../../../common/UserType';
import { AddtionalServiceOption } from './AddtionalServiceOption.js';

export const Addtional = (catagoryData) => {
    const { Search } = Input;

    const CatagoryData = catagoryData?.catagoryData

    const [ userData, setUserData ]=useState("");
    const [ visible, setVisible ] = useState(false)
    const [ searchText, setSearchText ] = useState('')
    const [ filteredResults, setFilteredResults] = useState([]);
    const [ setDataSort ] = useState([]);
    const [ order, setOrder ] = useState("ACS");      

    const response = useGetAddtionalServiceQuery(userData)

    const { data, isError, isFetching,isLoading, isSuccess, error } = response

    const _data = data?.data?.Items
    
    const onSearch = (value) => {
        setSearchText(value)
         if(value !== '' ){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
            setDataSort(filteredData)
        }
        else{
            setFilteredResults(_data)
            setDataSort(_data)
        }
    }


    const handleCancel = () => {
        setVisible(false)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
        {
            key: "name",
            title: "Name",
            dataIndex: 'name',
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        Sorting("name")
                    }
                };
            },
        }, 
        {
            key: "description",
            title: "Description",
            dataIndex: 'description',
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("description")
                    }
                };
            },
        },
        {
            key: "amount",
            title: "Amount",
            dataIndex: 'amount',
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("amount")
                    }
                };
            },
        },
        {
            key: "createdBy",
            title: "Created By",
            dataIndex: "createdBy",
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("createdBy")
                    }
                };
            },
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                    Sorting("createdAt")
                    }
                };
            },
            
        },
        {
            key: "userID",
            title: "Action",
            render: (record) => 
            <div>
                <AddtionalServiceOption
                    record={record}
                    userType={userTypes.CLIENTS}
                />
            </div>
        },
     
    ]

    const showModal = () => {
        setVisible(true)
    }


    return (
        <>
            <div className='flex flex-col'>
                {
                   visible && <AddAddtional visible={visible} catagoryData={catagoryData} handleCancel={handleCancel}/>
                }

                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch}
                        />
                    </div>
                        <div className="relative w-full lg:max-w-sm border-white justify-center">
                        <p>Select Services</p>
                        <select  value={userData} onChange={(event) => setUserData(event.target.value)} className="w-full p-2.5  bg-white  rounded-md ">
                            {
                                CatagoryData?.map( (data,index) => 
                                <>
                                {
                                    data?.services?.map( (service,index) => 
                                    <option key={index} value={service?.service?.id}>{service?.service?.title}</option> 
                                    )
                                }
                                </>
                                )
                            }
                        </select>
                        
                    </div>

                        <div className='mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add Addtional Service
                        </button>
                    </div>

                </div>

                <ClientsDataTable
                    key={_data}
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        {/* </Home> */}
        </>
    )
}

