import React, { useState } from 'react'
import { Menu, Dropdown } from 'antd'
import { CgDetailsMore } from 'react-icons/cg'
import { AiOutlineMore } from 'react-icons/ai'
import { EditAccessLevel } from './EditAccessLevel'

export const AccessLevelOption = ({ record, userType }) => {
    
    const id = record.id

    const [editVisible, setEditVisible]=useState(false)

    const handleActivate = () => {
        setEditVisible(true)
    }

    const handleCancel = () => {
        setEditVisible(false)
    }

    const menu = (
        <Menu style={{ width: 140 }}>
            {
                <div>
                    <Menu.Item key="1">
                        <div className='flex items-center' >
                            <div className='flex flex-col'>
                                <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                    <CgDetailsMore />
                                    <button onClick={handleActivate}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                </div>
            }
            <Menu.Divider />
        </Menu>
    )

    return (

        <div className='flex flex-col'>
            {
                editVisible &&
                <EditAccessLevel
                    visible={editVisible}
                    handleCancel={handleCancel}
                    // handleSubmit={handleEditClientsSubmit}
                    // handleFailed={handleEditClientsFailed}
                    acessLevel={record}
                />
            
            }

            <div className='flex flex-col cursor-pointer w-full items-center justify-center' >
                <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" key={id}>
                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <AiOutlineMore className='w-7 h-7' />
                    </span>
                </Dropdown>
            </div>
        </div>
    )
}
