
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Tabs } from 'antd';
import { Home } from '../Home';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSubscrptionByIDQuery } from '../../services/subscrption/subscrption.service'; 
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SubscriptionProfile } from './SubscriptionProfile'; 


export const SubscriptionDetail = () => {

    const { TabPane } = Tabs
    const navigate = useNavigate()

    const { SubscriptionId } = useParams()

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
    const response = useGetSubscrptionByIDQuery(SubscriptionId)

    const {  data,isLoading, isSuccess, isFetching } = response
    const washer=data?.data

    const callback = (key) => {
    }


    const back = () => {
        navigate('/Subscription')
    }

    return (
        <Home>
            <div className='flex items-center m-2 px-3'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
                {
                    isSuccess &&
                    <div className='flex items-center mx-3 px-3'>
                        {
                            washer?.isActive ?
                            <div className="relative">
                                <img className="w-11 h-11 rounded-full" src={washer?.profilePicture} alt={washer?.fullName} />
                                <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                            </div>
                           : <div className="relative">
                                <img className="w-10 h-10 rounded-full" src={washer?.profilePicture} alt={washer?.fullName} />
                                <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-red-600 border-2 border-white dark:border-gray-800 rounded-full"></span>
                            </div>
                        }
                        <p className='self-center mt-3 pl-2 text-md font-bold'>{washer?.fullName}</p>
                    </div>
                }
            </div>
            {
                isLoading || isFetching ?
                    (<div className='flex'>
                        <Spin indicator={antIcon} />
                    </div>) :
                    (
                        <div className='flex xl:mx-60 lg:mx-40 '>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Detail membership" key="1">
                                <SubscriptionProfile washer={washer} washerId={SubscriptionId} />
                            </TabPane>
                        </Tabs>
                        </div>
                    )
            }
        </Home>
    )
}
