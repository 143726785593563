
import React, { useEffect } from 'react'
import { Modal, Button, Form, Input, message} from 'antd'
import { useAddVerifyUserMutation } from '../../services/washer/washer.service'; 
import { toast, ToastContainer } from 'react-toastify'
import { useAddSendOTPMutation } from '../../services/user/user.service';

export const VerifyWasher = ({ visible, handleCancel ,record}) => {

    const [form] = Form.useForm();


    const [addVerifyUser, {  isLoading, isSuccess, isError, error }] = useAddVerifyUserMutation()
    const [addSendOTP ]=useAddSendOTPMutation()
    
    useEffect(() => {
        if (isSuccess) {
            toast.success("User Verifyed Successfully!",{toastId: "success"})
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
        if (isError) {
            toast.error(error?.data?.message)
            setTimeout(() => {
            }, 3000);
        }

    })


    const handleSubmit = async (values) => {
        try{
            const AdminLog={
                email: record?.id,
                code: values.code
            }

            const body = {
                ...AdminLog
            }

            await addVerifyUser(body)
        } catch (error) {
            return error
        }
    };

    const resendOTP = async ()=>{
        try{
            const AdminLog={
                email: record?.id,
            }
            const body = {
                ...AdminLog
            }
            await addSendOTP(body)
        } catch (error) {
            return error
        }

    }


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

   
    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item
                        label="User Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title!',
                            },
                        ]}
                    >
                        <Input type="email" disabled defaultValue={record?.id} />
                    </Form.Item>

                    <Form.Item
                        label="OTP Code"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                   
                    
                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Verify
                        </Button>
                    </Form.Item>
                </Form>
                
                <Button
                    loading={isLoading}
                    onClick={resendOTP}
                >
                    Resend OTP
                </Button>

            </Modal>

            
        </>

    )
}
