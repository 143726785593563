import React, {useState} from 'react'

import { Input } from 'antd'

import moment from 'moment'
import { useGetServiceByEmailQuery } from '../../../services/service/services.service'; 
import {  useNavigate, useParams } from 'react-router-dom';
import { ClientsDataTable } from '../ClientsDataTable';
import { userTypes } from '../../../common/UserType';
import { ServiceOption } from './ServiceOption';

export const ServiceHistory2 = () => {
    const { Search } = Input;

    const navigate=useNavigate()
    const [ searchText, setSearchText ] = useState('')
    const [ filteredResults, setFilteredResults] = useState([]);
    const  {clientsId}  = useParams()
    const [order, setOrder] = useState("ACS");

    const response = useGetServiceByEmailQuery(clientsId)

    const { data ,isLoading, isFetching, isSuccess, isError, error} = response

    const _data = data?.data?.Items


    const onSearch = (value) => {
        setSearchText(value)
        if (value !== '') {
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        } else {
            setFilteredResults(_data)
        }
    }
    
    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }


    const columns = [
        {
            key: "serviceDetail",
            title: "Service Type",
            dataIndex: "serviceType" ,   
            render: (text, record) => (
                <span>
                  {record.serviceDetail.services.map(service => (
                    <div key={service.serviceType.id}>
                      <p> {service.serviceType.title}</p>
                    </div>
                  ))}
                </span>
              ),  
            onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
        },
        {
            title: 'Additional Services',
            dataIndex: 'additional',
            key: 'additional',
            render: (text, record) => (
              <span className='w-full'>
                {record.serviceDetail.services.map(service => (
                  <div key={service.serviceType.id}>
                    {service.additional.map(additional => (
                      <div key={additional.id}>
                        <p>{additional.name}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </span>
            ),
        },
        {
            title: 'Request Status',
            dataIndex: 'requestStatus',
            key: 'requestStatus',
            render: (status) => {
              switch (status) {
                case 1:
                  return 'New Request';
                case 2:
                  return 'In Progress';
                case 3:
                  return 'Complete';
                case 4:
                  return 'Canceled';
                case 5:
                  return 'Pending';
                default:
                  return '-';
              }
            },
          },
        {
            key: "isScheduled",
            title: "Is Scheduled",
            dataIndex: ["isScheduled"],
            render: (record) => record ? <p>Yes</p> : <p>N0</p> ,
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
        },
        {
            key: "serviceDetail",
            title: "Is Payed",
            dataIndex: ["paymentMethodDetail","isPayed"],
            render: (record) => record ? <p>Yes</p> : <p>N0 </p> ,
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
        },
        {
            key: "washer",
            title: "Washer Id ",
            dataIndex: ["washerID"],
            onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
        },
        {
            key: "scheduleDetail",
            title: "Schedule Detail",
            dataIndex: ["scheduleDetail","date"],
            onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
            render: (record) => (
                <>
                {  record ? <div> { moment(record).format('L') } </div>
                    :
                    <div> Not Scheduled </div>
                }
                </>
            ),
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            key: 'vehicle',
            render: (text, record) => (
              <span>
                {record.serviceDetail.services.map(service => (
                  <div key={service.serviceType.id}>
                    <p> {service.vehicles[0].name}</p>
                  </div>
                ))}
              </span>
            ),
          },
        {
            key: "locationDetail",
            title: "Location",
            dataIndex: ["locationDetail","address","city"],
            onHeaderCell: () => { return { onClick: () => { Sorting("id") } }},
            onCell: (record) => { return { onClick: () => { navigate(`detail/${record.id}`)} }},
        },
        {
            key: "userID",
            title: "Action",
            render: (record) => 
            <div>
                <ServiceOption
                    record={record}
                    // handleEditModal={handleEditModal}
                    userType={userTypes.CLIENTS}
                />
            </div>
        },    
    ]

    return (
        <div className='flex flex-col w-full'>
            <div className='flex items-center justify-between flex-wrap-reverse'>
                <div className='mt-4 md:w-1/3 w-full'>
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}/>
                </div>
                {/* <div>
                    <button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={All}>
                            All
                        </button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={Arrvied}>
                            Arrived
                        </button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={Pending}>
                            Pending
                        </button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={Accepted}>
                            Accepted
                        </button>
                        <button className='bg-white shadow-md px-2 py-2' onClick={Complated}>
                            Complated
                        </button>
                    </button>
                </div> */}
            </div>
            {/* <TableData data={ searchText ? filteredResults: _data} />
            <div>ssssssssssssssssssssssss</div> */}
            {/* <div className='mt-8 w-full md:w-full lg:w-full xl:w-full overflow-x-auto'> */}
                <ClientsDataTable
                    key={_data}
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
        </div>
    )
}
