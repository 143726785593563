import React from 'react'
import { Modal, Button, Form, Input, message, Upload } from 'antd'
import { AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { useUpdateProfileMutation } from '../../services/admin/admin.service';


const UpdateProfile = ({ visible, handleCancel, user }) => {

    const [form] = Form.useForm();

    const [updateProfile, { isLoading, isSuccess, isError, error }] = useUpdateProfileMutation()

    if (isSuccess) {
        toast.success("User updated Successfully!",{
            toastId: "success" 
        })
        setTimeout(() => {
            handleCancel()
        }, 2500);
    }

    const profileData = {
        fullName: user?.fullName,
        email: user?.email,
        street: user?.address?.street,
        state: user?.address?.state,
        zipCode: user?.address?.zipCode,
        "SSN/EIN": user?.address?.["SSN/EIN"],
        phoneNumber: user?.phoneNumber,        
    }

    const handleSubmit = (values) => {
        const updateValues={
            fullName: values?.fullName,
            email: values?.id,
            phoneNumber: values?.phoneNumber,
            address: {
                state: values?.state,
                street: values?.street,
                zipCode: values?.zipCode,  
                "SSN/EIN": values?.["SSN/EIN"]  
            }
        }
        const email = user?.email
        updateProfile({
           email,
           values: updateValues 
        })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },

    }


    return (
    
        <Modal
            visible={visible}
            title={`Update ${user.fullName}`}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={null}       
            className='xl:client_modal_style lg:client_modal_style md:client_modal_style '
            confirmLoading={isLoading}
        >

            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }


            <div className='flex items-center justify-center mb-5'>
            <div className="relative">
                <img className="w-64 h-64 rounded-full" src={user?.profilePicture} alt={user?.fullName} />
            </div>
            <Upload {...props} showUploadList={false} >
                <Button
                    className='mt-10 -ml-6 client_upload_icon_test flex items-center justify-center'
                    >
                    <AiOutlinePlus className='w-6 h-6 -mx-2' type='upload' />
                </Button>
            </Upload>

            </div>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='flex flex-col py-5 w-full'
                initialValues={profileData}
            >
                <Form.Item
                    name="fullName"
                    label="Full name"
                >
                    <Input placeholder="Full Name" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                >
                    <Input placeholder="Email" type="email" />
                </Form.Item>

                <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                >
                    <Input placeholder="phone number" />
                </Form.Item>

                <Form.Item
                    label="Street"
                    name="street"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Street!',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>

                <Form.Item
                    label="State"
                    name="state"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your State!',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>

                <Form.Item
                    label="Zipcode"
                    name="zipCode"
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Zipcode!',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>

                <Form.Item
                    label="SSN/EIN"
                    name="SSN/EIN"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your SSN/EIN!',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                
                <Form.Item>
                    <Button type="primary"
                    
                        htmlType="submit"
                        className='ant-btn-primary-login bg-green-600'
                        loading={isLoading}
                    >
                        Update Profile
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default UpdateProfile