
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Avatar, Tabs } from 'antd';
import { Home } from '../Home';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAdminByIDQuery } from '../../services/admin/admin.service';
import { AiOutlineUser } from 'react-icons/ai'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AdminProfile } from './AdminProfile';
import { AccessLevel } from './accessLevel';
import { AdminLog } from './AdminLog/AdminLog';

export const AdminDetail = () => {

    const { TabPane } = Tabs;
    const navigate = useNavigate()

    const { adminId } = useParams()

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const response = useGetAdminByIDQuery(adminId)

    const { data, isFetching, isLoading, isSuccess } = response

    const admin = data?.data

    const callback = (key) => {
    }

    const back = () => {
        navigate('/user')
    }

    return (
        <Home>
            <div className='flex items-center m-2 px-3'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
                {
                    isSuccess &&
                    <div className='flex items-center mx-3 px-3'>
                        {
                            admin?.profilePicture ?
                            <Avatar src={admin.profilePicture} alt={admin.fullName} size={46} />
                                : <Avatar size={46} className='cursor-pointer'>
                                    <AiOutlineUser className='w-8 h-8 my-1' />
                                </Avatar>
                        }
                        <p className='self-center mt-3 pl-2 text-md font-bold'>{admin?.fullName}</p>
                    </div>
                }
            </div>
            {
                isLoading || isFetching ?
                    (<div className='flex'>
                        <Spin indicator={antIcon} />
                    </div>) :
                    (
                    <div className='flex flex-col xl:mx-60 lg:mx-40'>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Profile" key="1">
                                <AdminProfile admin={admin} />
                            </TabPane>
                            <TabPane tab="Admin Log" key="2">
                                <AdminLog />
                            </TabPane>
                            <TabPane tab="Acceses Level" key="4">
                                <AccessLevel />
                            </TabPane>
                        </Tabs>
                    </div>
                    )
            }
        </Home>
    )
}
