
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper } from '../providesTagsHelper'

const tagType = "CLIENT"

export const clientsUserApi = createApi({
    reducerPath: "clientsUserApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAllClients: builder.query({
            query: () => '/user/allClients',
            providesTags: (result) => providesTagsHelper(result, tagType,"CLIENT"),
        }),
        getAllRecycle: builder.query({
            query: () => '/recycleBin/all',
            providesTags: (result) => providesTagsHelper(result, tagType,"CLIENT"),
        }),
        getClientsByID: builder.query({
            query: (clientsId) => `user/profile/${clientsId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"CLIENT"),
        }),
        getVehicleByID: builder.query({
            query: (clientsId) => `vehicle/byEmail/${clientsId}`,
        }),
        addClients: builder.mutation({
            query: clientData => ({
                url: 'auth/signup',
                method: 'POST',
                body: clientData
            }),
            invalidatesTags: [{ type: tagType },'CLIENT'],
        }),
        addRecycle: builder.mutation({
            query: clientData => ({
                url: 'recycleBin/add',
                method: 'POST',
                body: clientData
            }),
            invalidatesTags: [{ type: tagType },'CLIENT'],
        }),
        deleteClient: builder.mutation({
            query: clientsId => ({
                url: `user/removeUser/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }, { type: 'CLIENT' }],
        }),
        updateProfile: builder.mutation({
            query: body => ({
                url: `user/updateProfile/${body?.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'CLIENT' },'CLIENT', 'WASHER', 'ADMIN'],
        }),
        addVerifyUser: builder.mutation({
            query: userData => ({
                url: 'auth/verify',
                method: 'POST',
                body: userData
            }),
            invalidatesTags: [{ type: tagType } ,{type:"CLIENT"}, {type:"WASHER"}, 'CLIENTS','WASHER'],
        }),

    })
})


export const { 
    useGetAllClientsQuery, 
    useGetAllRecycleQuery, 
    useGetClientsByIDQuery,
    useGetVehicleByIDQuery,
    useAddClientsMutation,
    useAddRecycleMutation,
    useDeleteClientMutation,
    useUpdateProfileMutation,
    useAddVerifyUserMutation,

 } = clientsUserApi



