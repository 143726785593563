
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Home.css';
import { Navbar } from './navbar';
import { AiOutlineLogout, AiOutlineSetting, AiOutlineUser, AiOutlineHome, AiOutlineDelete, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import {  useDispatch } from 'react-redux'
import { logout, reset } from '../features/authSlice'
import { useGetUserProfileQuery } from '../services/user/user.service';


export const Home = (props) => {

    const { Header, Content, Sider } = Layout;
    const params = useLocation()
    const [selectedKey, setSelectedKey] = useState(params.pathname);
    
    const response = useGetUserProfileQuery()

    const { data } = response
    const user = data?.data

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/')
        window.location.reload();
    }

   
    const RenderUserRole = () =>{
        setSelectedKey(params.pathname)
        if(user?.userRole==="ADMIN"){
            return (
                <div>
                <Sider
                    width={250}
                    className="site-layout-background mt-10 py-2 sidebar-container relative"
                    breakpoint="md"
                    collapsedWidth="0"
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={params.pathname}
                        style={{ height: '100%', borderRight: 0, marginTop: 50 }}
                    >
                        <Menu.Item key="/dashboard"  icon={<AiOutlineHome style={{ color: selectedKey === '/dashboard' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/dashboard" className='nav-text'>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/clients' ? 'white' : undefined ,fontWeight: 'bold' }}/>} >
                            {/* selectedKey.startsWith('/clients') */}
                            <NavLink to="/clients" className='nav-text ' >
                                Clients
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item key="/washer" icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/washer' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/washer" className='nav-text'>
                                Washer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/user" icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/user' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/user" className='nav-text  '>
                                User
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/service" icon={<AiOutlineSetting style={{ color: selectedKey === '/service' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/service" className='nav-text'>
                                Service
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/Subscription" icon={<AiOutlineSetting style={{ color: selectedKey === '/Subscription' ? 'white' : undefined ,fontWeight: 'bold'}}/>}>
                            <NavLink to="/Subscription" className='nav-text'>
                                Subscription 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/deletedUser" icon={<AiOutlineDelete style={{ color: selectedKey === '/deletedUser' ? 'white' : undefined ,fontWeight: 'bold' }} />} >
                            <NavLink to="/deletedUser" className='nav-text'>
                                Deleted Bucket
                            </NavLink>
                        </Menu.Item>
                    </Menu>  
                    
                    <div className='absolute bottom-4 left-0 w-full h-20'>
                        <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={() => onLogout()}>
                            <AiOutlineLogout className='w-6 h-6 mr-3' />
                            <p className='text-md font-bold mt-2'>Logout</p>
                        </span>
                    </div>
                </Sider> 
                </div>
            ) 
        }
        if(user?.userRole==="CLIENT SERVICE"){
            return (
                <div>
                <Sider
                    width={250}
                    className="site-layout-background mt-10 py-2 sidebar-container relative"
                    breakpoint="lg"
                    collapsedWidth="0"
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={params.pathname}
                        style={{ height: '100%', borderRight: 0, marginTop: 50 }}
                    >
                        <Menu.Item key="/dashboard"  icon={<AiOutlineHome style={{ color: selectedKey === '/dashboard' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/dashboard" className='nav-text'>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/clients' ? 'white' : undefined ,fontWeight: 'bold' }}/>} >
                            <NavLink to="/clients" className='nav-text ' >
                                Clients
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item key="/Subscription" icon={<AiOutlineSetting style={{ color: selectedKey === '/Subscription' ? 'white' : undefined ,fontWeight: 'bold'}}/>}>
                            <NavLink to="/Subscription" className='nav-text'>
                                Subscription 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/deletedUser" icon={<AiOutlineDelete style={{ color: selectedKey === '/deletedUser' ? 'white' : undefined ,fontWeight: 'bold' }} />} >
                            <NavLink to="/deletedUser" className='nav-text'>
                                Deleted Bucket
                            </NavLink>
                        </Menu.Item>
                    </Menu>  
                    
                    <div className='absolute bottom-4 left-0 w-full h-20'>
                        <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={() => onLogout()}>
                            <AiOutlineLogout className='w-6 h-6 mr-3' />
                            <p className='text-md font-bold mt-2'>Logout</p>
                        </span>
                    </div>
                </Sider> 
                </div>
            ) 

        }
        if(user?.userRole==="SUPER ADMIN"){
            return (
                <div>
                <Sider
                    width={250}
                    className="site-layout-background mt-10 py-2 sidebar-container relative"
                    breakpoint="lg"
                    collapsedWidth="0"
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={params.pathname}
                        style={{ height: '100%', borderRight: 0, marginTop: 50 }}
                    >
                        <Menu.Item key="/dashboard"  icon={<AiOutlineHome style={{ color: selectedKey === '/dashboard' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/dashboard" className='nav-text'>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/clients' ? 'white' : undefined ,fontWeight: 'bold' }}/>} >
                            <NavLink to="/clients" className='nav-text ' >
                                Clients
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item key="/washer" icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/washer' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/washer" className='nav-text'>
                                Washer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/user" icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/user' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/user" className='nav-text  '>
                                User
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/service" icon={<AiOutlineSetting style={{ color: selectedKey === '/service' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/service" className='nav-text'>
                                Service
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/Subscription" icon={<AiOutlineSetting style={{ color: selectedKey === '/Subscription' ? 'white' : undefined ,fontWeight: 'bold'}}/>}>
                            <NavLink to="/Subscription" className='nav-text'>
                                Subscription 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/deletedUser" icon={<AiOutlineDelete style={{ color: selectedKey === '/deletedUser' ? 'white' : undefined ,fontWeight: 'bold' }} />} >
                            <NavLink to="/deletedUser" className='nav-text'>
                                Deleted Bucket
                            </NavLink>
                        </Menu.Item>
                    </Menu>  
                    
                    <div className='absolute bottom-4 left-0 w-full h-20'>
                        <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={() => onLogout()}>
                            <AiOutlineLogout className='w-6 h-6 mr-3' />
                            <p className='text-md font-bold mt-2'>Logout</p>
                        </span>
                    </div>
                </Sider> 
                </div>
            ) 

        }
        if(user?.userRole==="WASHER SERVICE"){
            return (
                <div>
                <Sider
                    width={250}
                    className="site-layout-background mt-10 py-2 sidebar-container relative"
                    breakpoint="lg"
                    collapsedWidth="0"
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={params.pathname}
                        style={{ height: '100%', borderRight: 0, marginTop: 50 }}
                    >
                        <Menu.Item key="/dashboard"  icon={<AiOutlineHome style={{ color: selectedKey === '/dashboard' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/dashboard" className='nav-text'>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/clients' ? 'white' : undefined ,fontWeight: 'bold' }}/>} >
                            {/* selectedKey.startsWith('/clients') */}
                            <NavLink to="/clients" className='nav-text ' >
                                Clients
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item key="/washer" icon={<AiOutlineUsergroupAdd style={{ color: selectedKey === '/washer' ? 'white' : undefined ,fontWeight: 'bold' }}/>}>
                            <NavLink to="/washer" className='nav-text'>
                                Washer
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/Subscription" icon={<AiOutlineSetting style={{ color: selectedKey === '/Subscription' ? 'white' : undefined ,fontWeight: 'bold'}}/>}>
                            <NavLink to="/Subscription" className='nav-text'>
                                Subscription 
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/deletedUser" icon={<AiOutlineDelete style={{ color: selectedKey === '/deletedUser' ? 'white' : undefined ,fontWeight: 'bold' }} />} >
                            <NavLink to="/deletedUser" className='nav-text'>
                                Deleted Bucket
                            </NavLink>
                        </Menu.Item>
                    </Menu>  
                    
                    <div className='absolute bottom-4 left-0 w-full h-20'>
                        <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={() => onLogout()}>
                            <AiOutlineLogout className='w-6 h-6 mr-3' />
                            <p className='text-md font-bold mt-2'>Logout</p>
                        </span>
                    </div>
                </Sider> 
                </div>
            ) 

        }
        if(user?.userRole==="service manager"){
            return (
                <div>
                <Sider
                    width={250}
                    className="site-layout-background mt-10 py-2 sidebar-container relative"
                    breakpoint="lg"
                    collapsedWidth="0"
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={params.pathname}
                        style={{ height: '100%', borderRight: 0, marginTop: 50 }}
                    >
                        <Menu.Item key="/dashboard"  icon={<AiOutlineHome />}>
                            <NavLink to="/dashboard" className='nav-text'>
                                Dashboard
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUser />} >
                            <NavLink to="/clients" className='nav-text ' >
                                Clients
                            </NavLink>
                        </Menu.Item>
                        
                        <Menu.Item key="/washer" icon={<AiOutlineUser />}>
                            <NavLink to="/washer" className='nav-text'>
                                washer
                            </NavLink>
                        </Menu.Item>


                        <Menu.Item key="/deletedUser" icon={<AiOutlineDelete />}>
                            <NavLink to="/deletedUser" className='nav-text'>
                                Deleted Bucket
                            </NavLink>
                        </Menu.Item>

                    </Menu>  
                    
                    <div className='absolute bottom-4 left-0 w-full h-20'>
                        <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={() => onLogout()}>
                            <AiOutlineLogout className='w-6 h-6 mr-3' />
                            <p className='text-md font-bold mt-2'>Logout</p>
                        </span>
                    </div>
                </Sider> 
                </div>
            ) 
        }
        else{
            return(
                <div>
                </div>
            )
        }
    }

    return (
        <Layout className='app-body'>
            <Header
                className="header navbar-container">
                <Navbar />
            </Header>


            <Layout>
                {
                    <RenderUserRole />
                }

                <Layout className="site-layout" >
                    <Content
                        className="site-layout-background mt-10 py-2 w-full body_content"
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
