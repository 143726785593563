import { Tooltip } from 'antd'
import React from 'react'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts'

export const DataGraphWeekly = (dataReport) => {

    const dataGraphWeekly = [
        {
            name: 'week 1',
            uv: 4000,
            $: 2400,
            amt: 2400,
        },
        {
            name: 'week 2',
            uv: 3000,
            $: 1398,
            amt: 2210,
        },
        {
            name: 'week 3',
            uv: 2000,
            $: 9800,
            amt: 2290,
        },
        {
            name: 'week 4',
            uv: 2780,
            $: 3908,
            amt: 2000,
        },
        {
            name: 'week 5',
            uv: 1890,
            $: 4800,
            amt: 2181,
        },
        {
            name: 'week 6',
            uv: 2390,
            $: 3800,
            amt: 2500,
        },
        {
            name: 'week 7',
            uv: 3490,
            $: 4300,
            amt: 2100,
        },
    ]

  return (
    <div>
        
        <ResponsiveContainer width="100%" height={200} className='mt-5'>
            <AreaChart
                width={500}
                height={200}
                data={dataGraphWeekly}
                syncId="anyId"
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="$" stroke="#00a1ff" fill="#00a1ff" />
            </AreaChart>
        </ResponsiveContainer>

    </div>
  )
}
