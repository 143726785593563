import React, { useState } from 'react'
import {  message } from 'antd'
import { ToastContainer } from 'react-toastify'
import { EditSubscrptionModal } from './EditSubscrptionModal' 
import { ConfirmDeleteSubscrpition } from '../shared/ConfirmDeleteSubscrpition' 

export const SubscriptionProfile = ({ washer,washerId }) => {
        

    const [showModal, setShowModal] = useState({
        editWasher: false,
        resetPassword: false,
        deleteWasher: false,
    })

    const showEditModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editWasher: true
        }))
    }

    const editModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editWasher: false
        }))
    }

    const handleEditWasherSubmit = (values) => {
    }

    const handleEditWasherFailed = () => {
        message.error('Submit failed!');
    }
  
    const showDeleteModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteWasher: true
        }))
    }

    const deleteModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteWasher: false
        }))
    }

    const handleDeleteWasherSubmit = (values) => {
    }

    return (
        <div className='flex flex-col w-full '>

        <ToastContainer />
            {
                showModal.editWasher &&
                <EditSubscrptionModal
                    visible={showModal.editWasher}
                    handleCancel={editModalCancel}
                    handleSubmit={handleEditWasherSubmit}
                    handleFailed={handleEditWasherFailed}
                    washer={washer}
                />
            }
            {
                showModal.deleteWasher &&
                <ConfirmDeleteSubscrpition
                    visible={showModal.deleteWasher}
                    onCancel={deleteModalCancel}
                    handleOk={handleDeleteWasherSubmit}
                    title="Delete Washer"
                    okText="Delete"
                    warningText="Are you sure to delete this client"
                    user={washer}
                />
                
            }

            <div className=' w-full p-3 py-7'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                        <p>Personal Information</p>
                    </div>
                    <div className='flex px-3 items-center'>
                        <p
                            className='px-2 cursor-pointer'
                            onClick={showEditModal}
                        >
                            Edit
                        </p>
                       
                        <p className='text-red-800 px-2 cursor-pointer'
                            onClick={showDeleteModal}
                        >
                            Delete
                         </p>
                    </div>
                </div>
                <div className='flex items-center  content-center justify-between w-full mt-7'> 
                <table className="table-fixed justify-center">
                    <tbody>
                        <tr>
                            <td>SubTitle</td>
                            <td className='px-20'>{washer?.subTitle}</td>
                        </tr>
                        <tr>
                            <td className=''>Price</td>
                            <td className='px-20'>{washer?.price}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td className='px-20'>{washer?.description}</td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td className='px-20'>{washer?.duration}</td>
                        </tr>
                        <tr>
                            <td>Request ID</td>
                            <td className='px-20'>{washer?.requestID}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}


