
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, message, TreeSelect } from 'antd'
import { toast, ToastContainer } from 'react-toastify'
import { useAddAdtionalServiceMutation,  useGetServiceCatagoryQuery } from '../../../services/service/services.service' 


export const AddAddtional = ({ visible, handleCancel, catagoryData }) => {

    const [form] = Form.useForm();

    const CatagoryData= catagoryData?.catagoryData

    const [ userData, setUserData ]=useState("");
    const [ valueSelect, setValueSelect ] = useState("");
    const [ serviceData, setServiceData ]=useState("");

    const response = useGetServiceCatagoryQuery(userData)
    const { data } = response
    const _data = data?.data?.Items


    const [addAdtionalService, {  isLoading, isSuccess, isError, error }] = useAddAdtionalServiceMutation()

    useEffect(()=>{
        if (isSuccess) {
                toast.success("addtional service created successfuly!", {
                    toastId: "success" 
                })
                setTimeout(() => {
                    handleCancel()
                }, 3000);
            }
        if (isError) {
            toast.error(error?.data.data , {
                toastId: "error" 
            })
            setTimeout(() => {
            }, 3000);
        }    
    })

    const onChange = (newValue) => {
        setValueSelect(newValue);
        setUserData(newValue)
    };

    const onChangeService = (newValue) => {
        setServiceData(newValue);
    };

    const handleSubmit = async (values) => {
        try {
                const id= serviceData
                const serviceCatagory={
                    name: values.name,
                    amount: values.amount,
                    description: values.description,
                }

                const body = {
                    id: id,
                    ...serviceCatagory
                }

                await addAdtionalService(body)

        } catch (error) {
            return error
        }
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <>
            <ToastContainer />

            <Modal
                open={visible}
                title="Add Sevice Catagory"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item>
                    <div className="relative flex flex-col border-white w-full ">
                        <div className='flex w-full'>
                            <p>Select Catagory</p>
                        </div>
                        <div className='w-full flex '>
                        <TreeSelect
                            style={{
                                width: '100%',
                            }}
                            value={valueSelect}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={CatagoryData?.map( (data,index) => ({
                                    title : data?.name,
                                    value : data?.id,
                                })
                            )}
                            placeholder="Please select"
                            treeDefaultExpandAll
                            onChange={onChange}
                        />
                        </div>
                    </div>
                    </Form.Item>

                    <Form.Item>
                    <div className="relative flex flex-col border-white w-full ">
                        <div className='flex w-full'>
                            <p>Select Catagory</p>
                        </div>
                        <div className='w-full flex '>
                        <TreeSelect
                            style={{
                                width: '100%',
                            }}
                            value={serviceData}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={_data?.map( (data,index) => ({
                                    title : data?.title,
                                    value : data?.id,
                                })
                            )}
                            placeholder="Please select"
                            treeDefaultExpandAll
                            onChange={onChangeService}
                        />
                        </div>
                    </div>
                    </Form.Item>


                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your amount!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your description!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Add Catagory
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
