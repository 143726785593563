
import React, { useEffect } from 'react'
import { Modal, Button, Form, Input, message} from 'antd'
import { useAddSubscrptionMutation } from '../../services/subscrption/subscrption.service' 
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer';


export const AddSubscrptionModal = ({ visible, handleCancel }) => {
    window.Buffer = Buffer;

    const navigate = useNavigate()

    const [form] = Form.useForm();

    const [addSubscrption, { isLoading, isSuccess, isError, error }] = useAddSubscrptionMutation()
    
    useEffect(() => {
        if (isSuccess) {
            toast.success("Subscrption Added successfully!",{toastId: "success"})
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
        if (isError) {
            toast.error(error?.data.data)
            setTimeout(() => {
                navigate("/washer")
            }, 3000);
        }

    })

    const handleSubmit = async (values) => {
            
        const body = {
            ...values,
        }

        await addSubscrption(body)
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add Washer"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                        label="SubTitle"
                        name="subTitle"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your SubTitle!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Duration"
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your duration!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="price"
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your price!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Description "
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your description!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
