import { Tooltip } from 'antd'
import React from 'react'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts'

export const DataGraphYearly = (dataReport) => {
  
    const dataGraphYearly = [
        {
            name: '2017',
            uv: 0,
            $: 0,
            amt: 0,
        },
        {
            name: '2018',
            uv: 0,
            $: 0,
            amt: 0,
        },
        {
            name: '2019',
            uv: 0,
            $: 0,
            amt: 0,
        },
        {
            name: '2020',
            uv: 0,
            $: 0,
            amt: 0,
        },
        {
            name: '2021',
            uv: 1890,
            $: 2000,
            amt: 2181,
        },
        {
            name: '2022',
            uv: 2390,
            $: 3000,
            amt: 2500,
        },
        {
            name: '2023',
            uv: 3490,
            $: 4800,
            amt: 2100,
        },
    ]

  return (
    <div>
        
        <ResponsiveContainer width="100%" height={200} className='mt-5'>
            <AreaChart
                width={500}
                height={200}
                data={dataGraphYearly}
                syncId="anyId"
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="$" stroke="#00a1ff" fill="#00a1ff" />
            </AreaChart>
        </ResponsiveContainer>

    </div>
  )
}
