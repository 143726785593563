import React, { useState } from 'react'

import './login.css'

import worxxLogo from '../../assets/img/worxx.png'
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { reset, login } from '../../features/authSlice'

export const Login = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const [role, setRole ]=useState("ADMIN")

    const { isError, isSuccess, isLoading, message } = useSelector((state) => state.auth)
    
    const storedEmail = localStorage.getItem('loginEmail');
    const storedPassword = localStorage.getItem('loginPassword');


        if (isError) {
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER
              });
            setTimeout(() => {
                navigate("/")
            }, 2000);
            dispatch(reset())
        }
        if (isSuccess) {
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER
              });
            setTimeout(() => {
                navigate("/dashboard")
            }, 3000);
            dispatch(reset())
        }

    const onFinish = (values) => {
        const email = values.email
        const password = values.password
        const userRole = role
        const userData = { userRole, email, password }

        localStorage.setItem('loginEmail', email);
        localStorage.setItem('loginPassword', password);

        dispatch (login(userData))
    }

    const onFinishFailed = (errorInfo) => {
    };


    return (
        <>
            <ToastContainer />

            <div className='md:flex items-center login_container h-screen'>
                <div className='md:w-1/2 h-full login_left hidden md:block lg:block'>
                    <div className='image_div hidden md:block lg:block'>
                        <img src={worxxLogo} alt='worxx logo' className='logo_1' />
                    </div>
                </div>
                <div className='md:w-1/2 w-full mt-10 md:my-0'>
                    <div className='logo_sm md:hidden lg:hidden'>
                        <img src={worxxLogo} alt='worxx logo' className='logo_1' />
                    </div>

                    <div className='flex flex-col items-center w-full'>
                        <h3 className='header_3 hidden md:block'>Admin Panel</h3>
                        {
                            isError && 
                            <p className='text-red-500 text-md font-bold'>
                                {message}
                            </p>
                        }
                        <div className='flex flex-col w-4/5'>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            initialValues={{
                                remember: true,
                                email: storedEmail,
                                password: storedPassword
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                            confirmLoading= {isLoading}
                        >
                        <Form.Item label="User Role" name="userRole"  >
                            <select  value={role} onChange={(event) => setRole(event.target.value)} className="w-full p-2.5  bg-white  rounded-md justify-center">
                                <option value="ADMIN">Admin</option>
                                <option value="SUPERADMIN">Super Admin</option>
                                <option value="CLIENTSERVICE service">Client service</option>
                                <option value="WASHERSERVICE">Washer service</option>
                            </select>
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input type="email"  />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                        {/* <div className='w-1/2'>
                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                className=''
                            >
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </div> */}

                        <Form.Item>
                            <Button 
                                type="primary"
                                htmlType="submit"
                                className='ant-btn-primary-login bg-green-600'
                                loading={isLoading}
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
