
import React, { useState } from 'react'
import { Tabs } from 'antd'
import { Home } from '../Home';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { AddAdminLog } from './AdminLog/AddAdminLog';
import { AdminLogAccessL } from './AdminLogAccessL';
import { AccessLevelAll } from './accessLevel/AccessLevelAll';

export const AdminLogAll = () => {
    const { TabPane } = Tabs;

    const [visible, setVisible] = useState(false)
    const navigate=useNavigate()

    const back = () => {
        navigate('/user')
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const callback = (key) => {
    }
    
    return (
        <Home>
            <div className='flex flex-col'>
            <div className='flex items-center m-2 px-3'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
            </div>
            <div className=' flex flex-col xl:mx-60 lg:mx-40'>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Admin Log All" key="1">
                        <AdminLogAccessL  />
                    </TabPane>
                    <TabPane tab="Access Level" key="2">
                        <AccessLevelAll />
                    </TabPane>
                </Tabs>
            </div>
                {
                    visible && <AddAdminLog visible={visible} handleCancel={handleCancel} />
                }
            </div>
        </Home>
    )
}

