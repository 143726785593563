
import React, { useEffect } from 'react'
import { Modal, Button, Form, Input, message} from 'antd'
import { useAddAdminRoleMutation } from '../../services/admin/admin.service'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom';


export const AddUserRole = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();

    const navigate = useNavigate()

    const [addAdmin, {  isLoading, isSuccess, isError, error }] = useAddAdminRoleMutation()
    
    useEffect(() => {
        if (isSuccess) {
            toast.success("User Role Added successfully!",{toastId: "success"})
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
        if (isError) {
            toast.error(error?.data?.data)
            setTimeout(() => {
               navigate("/user")
            }, 3000);
        }

    })


    const handleSubmit = async (values) => {
        try{
            const AdminLog={
                userRole: values.userRole,
            }

            const body = {
                ...AdminLog
            }

            await addAdmin(body)
        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

   
    return (
        <>
            <ToastContainer />
            <Modal
                visible={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                {/*                   
                    <Form.Item label="User Role" name="userRole"  >
                        <Select onChange={userRoleOptions}>
                            <Select.Option value="customer service">Customer service</Select.Option>
                            <Select.Option value="washer service">washer service</Select.Option>
                            <Select.Option value="admin">Admin</Select.Option>
                            <Select.Option value="super admin">Super Admin</Select.Option>
                        </Select>
                    </Form.Item> */}
                    
                    <Form.Item
                        label="User Role"
                        name="userRole"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    {/* <Form.Item
                        label="Note"
                        name="note"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item> */}

                    
                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
