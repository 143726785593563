import React from 'react'
import { AccessLevelDataTable } from './AccessLevelDataTable'

export const AccessLevel = () => {

    return (
        <div className='flex flex-col w-full'>
            <AccessLevelDataTable />
        </div>
    )
}
