import React, { useEffect } from 'react'

import { Modal } from 'antd'
import { useDeleteUserMutation } from '../../services/admin/admin.service' 
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAddRecycleMutation } from '../../services/clients/clients.service'

export const ConfirmDeleteUser = ({ visible, title, onCancel, okText, user }) => {

    const navigate = useNavigate()
    const [deleteUser, { isLoading, isSuccess, isError, error }] = useDeleteUserMutation()
    const [addRecycle] = useAddRecycleMutation()
    const {adminId}=useParams()
    const id=adminId

    const handleOk = () => {
        deleteUser(id)
        const body = {
            email: id,
            userRole: "ADMIN",
        }
         addRecycle(body)
    }

    useEffect (() => {
        if(isSuccess){
            toast.success("User Deleted Successfully!")
            navigate('/user') 
        }
    })

    return (
        
        <Modal
            visible={visible}
            title={title}
            onOk={handleOk}
            onCancel={onCancel}
            okText={okText}
            className='user_modal_style'
            confirmLoading={isLoading}
        >

            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }

            <p>
                Are you sure, you want to delete <span className=" text-red-700 ">{user?.fullName}</span> 
            </p>
            <p>This will be deleted so soon</p>
        </Modal>
    )
}
