import React, { useState } from 'react'

import worxxLogo from '../../assets/img/worxx.png'

import { Avatar, Menu, Button, Dropdown } from 'antd'
import {
    MenuUnfoldOutlined,
} from '@ant-design/icons';

import { AiOutlineLogout, AiOutlineSetting, AiOutlineUser, AiOutlineHome, AiOutlineDelete, AiOutlineUsergroupAdd, AiOutlineClose } from 'react-icons/ai'

import {  GrServices } from 'react-icons/gr'
import { NotificationOutlined , NotificationTwoTone} from '@ant-design/icons';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { logout, reset } from '../../features/authSlice'
import { useGetUserProfileQuery } from '../../services/user/user.service'

import './navbar.css'
import { AddNotification } from '../notification/AddNotification';
import { AddNotificationByRole } from '../notification/AddNotificantionByRole';

export const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleByRole, setVisibleByRole] = useState(false)

    const params = useLocation()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const response = useGetUserProfileQuery()
    const { data } = response
    const userInfo = data?.data
   
    // useEffect(() => {
    //     const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    //     const decoded = currentUser.token
    // })


    const userLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/login')
        window.location.reload();
    }

    const showModal = () => {
        setVisible(true)
    }

    const showModalByRole = () =>{
        setVisibleByRole(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }
    const handleCancelByRole = () =>{
        setVisibleByRole(false)
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const CustomMenu = () => {
        return (
            <div className='relative h-96 z-10'>
                <Menu
                    mode="inline"
                    selectedKeys={params.pathname}
                    style={{ height: '90%', borderRight: 0, marginTop: 5, marginRight: 0 }}
                >
                    <Menu.Item key="/dashboard"  icon={<AiOutlineHome />}>
                        <NavLink to="/dashboard" className='nav-text'>
                            Dashboard
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="/clients" className='nav-text' icon={<AiOutlineUsergroupAdd />} >
                        <NavLink to="/clients" className='nav-text ' >
                            Clients
                        </NavLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/washer" icon={<AiOutlineUsergroupAdd />}>
                        <NavLink to="/washer" className='nav-text'>
                            Washer
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="/user" icon={<AiOutlineUsergroupAdd />}>
                        <NavLink to="/user" className='nav-text  '>
                            User
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="/service" icon={<GrServices />}>
                        <NavLink to="/service" className='nav-text'>
                            Service
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="/Subscription" icon={<AiOutlineSetting />}>
                        <NavLink to="/Subscription" className='nav-text'>
                            Subscription 
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="/deletedUser" icon={<AiOutlineDelete />}>
                        <NavLink to="/deletedUser" className='nav-text'>
                            Deleted Bucket
                        </NavLink>
                    </Menu.Item>
                </Menu>
                <div className='absolute bottom-1 left-0 w-full h- '>
                    <span className=' flex items-center ml-4 mt-2 cursor-pointer' onClick={userLogout}>
                        <AiOutlineLogout className='w-5 h-5 mr-3' />
                        <p className='text-sm  mt-2'>Logout</p>
                    </span>
                </div>
            </div>
        )
    }

    const menu = (
        <Menu>
            <Menu.Item key="/profile" icon={<AiOutlineHome />}>
                <NavLink to="/profile" className='nav-text'>
                    Profile
                </NavLink>
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="/change-password" icon={<AiOutlineHome />}>
                <NavLink to="/change-password" className='nav-text'>
                    Change Password
                </NavLink>
            </Menu.Item>
            <div className=''>
                <span className='flex items-center ml-4 mt-2 cursor-pointer' onClick={userLogout}>
                    <AiOutlineLogout className='w-5 h-5 mr-3' />
                    <p className='text-sm  mt-2'>Logout</p>
                </span>
            </div>
        </Menu>
    );

    const notificationMenu = (
        <Menu>
            <div className=''>
                <span className='flex items-center ml-4 mt-2  px-2 w-full' onClick={showModal}>
                    <NotificationOutlined className='w-4 h-4 mr-3' />
                    <p className='text-sm  mt-2 nav-text w-full '>Notifiy single user</p>
                </span>
            </div>
            <div className=''>
                <span className='flex items-center ml-4 mt-2 cursor-pointer w-full' onClick={showModalByRole}>
                    <NotificationOutlined className='w-4 h-4 mr-3' />
                    <p className='text-sm  mt-2 nav-text'>Notify by role</p>
                </span>
            </div>
        </Menu>
    );


    return (
        <div className='flex justify-between h-full relative'>
            {
                visible && <AddNotification visible={visible} handleCancel={handleCancel}/>
            }
            {
                visibleByRole && <AddNotificationByRole visible={visibleByRole} handleCancel={handleCancelByRole}/>
            }


            <div style={{ width: 256 }} className='md:hidden block'>
                <Button type="primary" onClick={toggleMenu}
                    style={{ marginBottom: 16, background: "#41b56e", border: "none", color: "#321" }}>
                    {React.createElement(isMenuOpen ? AiOutlineClose : MenuUnfoldOutlined)}
                </Button>
                {
                    isMenuOpen && <CustomMenu />
                }
            </div>
            <div className='flex w-1/2'>
                <img src={worxxLogo} alt='worxx logo' className='hidden md:block lg:block worxx_logo' />
            </div>
            <div className='mx-3 py-3 navbar-right '>
                <div className=' w-1/2 relative h-full md:w-full flex items-center justify-center md:mt-5 mt-5'>
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" className='navbar-menu'>
                        <div className='flex flex-row'>
                        
                        <div className=' justify-center items-center hidden lg:block md:block xl:block'>
                            {userInfo?.fullName}
                        </div>
                            
                        <p onClick={e => e.preventDefault()}>
                        {
                            userInfo?.profilePicture ?
                                <div className='flex items-center  px-3'>
                                    <div className="relative w-full">
                                        <img className="w-14 h-14 rounded-full" src={userInfo.profilePicture} alt={userInfo.fullName} />
                                    </div>
                                </div>
                                : <Avatar size={34} className='cursor-pointer'>
                                    <AiOutlineUser className='w-6 h-6 my-1' />
                                </Avatar>
                        }
                        </p>
                        </div>
                    </Dropdown>
                    
                    <Dropdown overlay={notificationMenu} trigger={['click']} placement="bottomCenter" className='navbar-menu'>
                        <div className='flex flex-row'>                            
                            <p onClick={e => e.preventDefault()}>
                                {/* // <NotificationOutlined className='justify-center w-10 h-10 my-1 bg-gray-300' /> */}
                                <NotificationTwoTone className='justify-center w-10 h-10 my-1' />
                            </p>
                        </div>
                    </Dropdown>

                </div>
            </div>

        </div>
    )
}
