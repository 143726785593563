
import React, { useState } from 'react'
import { Home } from '..'
import {  ToastContainer } from 'react-toastify'

import { LoadingOutlined } from '@ant-design/icons';
import { Radio, DatePicker, Spin, Tooltip } from 'antd';
import './dashboard.css'

import { useGetUserQuery } from '../../services/user/user.service';
import { DataGraphMonthly } from './DataGraphMonthly';
import { DataGraphDaily } from './DataGraphDaily';
import { DataGraphWeekly } from './DataGraphWeekly';
import { DataGraphYearly } from './DataGraphYearly';

export const Dashboard = () => {

    const { RangePicker } = DatePicker;

    const response = useGetUserQuery()
    const [dataGraphValue, setDataGraphValue] = useState("monthly");

    const {data,  isError, isFetching, isLoading, error } = response
    const user=data?.data

    const activeWasher = user?.activeUsers?.washer
    const activeClient = user?.activeUsers?.client
    const inActiveWasher = user?.inactiveUsers?.washer
    const inActiveClient = user?.inactiveUsers?.client
    const crushWasher = user?.crushReport?.washer
    const crushClient = user?.crushReport?.client
    const totalActive = activeWasher+activeClient
    const totalInActive = inActiveWasher+inActiveClient
    const totalWasher = activeWasher + inActiveWasher
    const totalClient = activeClient + inActiveClient
    const totalUsers = totalWasher + totalClient
    const totalCrush = crushWasher + crushClient  

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    
    const activeUsers = <span>
        <div>InActive Client {activeClient}</div>
        <div>InActive washer {activeWasher}</div>
    </span>

    const inActiveUsers = <span>
        <div>Active Client {inActiveClient}</div>
        <div>Active Washer {inActiveWasher} </div>
    </span>
  

    return (
        <Home>
            <ToastContainer />
            <div className='flex p-2 w-full rounded-md'>
                <div className='flex items-center md:justify-end flex-start w-full py-1 px-2 flex-wrap'>
                    <Radio.Group value={dataGraphValue} onChange={(event) => setDataGraphValue(event.target.value)} className=''>
                        <Radio.Button value="daily">Daily</Radio.Button>
                        <Radio.Button value="weekly">Week</Radio.Button>
                        <Radio.Button value="monthly">Month</Radio.Button>
                        <Radio.Button value="yearly">Year</Radio.Button>
                    </Radio.Group>
                    <RangePicker renderExtraFooter={() => 'extra footer'} />
                </div>
            </div>

            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status} 
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }
           
            <div className='mt-10 '>
                <div className="flex flex-row w-full  xl:w-1/2 lg:w-1/2 md:w-1/2 justify-center xl:mx-24 lg:mx-24 ">
                
                <div className='flex w-1/4 mt-10 flex-wrap'>
                    {
                        <Tooltip placement="bottom" title={inActiveUsers} color='green'>
                            <div
                                className=' flex flex-col bg-white border  border-blue-400 xl:px-5 lg:px-5 md:px-5 mr-5 mt-3 items-center justify-center pt-5 hover:'>
                                <h1 className=''  >
                                    Active users
                                </h1>
                            <div className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></div>
                            <div className='dashboard_items_value'>

                                {
                                    isLoading || isFetching ? (<Spin indicator={antIcon} />) : (
                                        <p className=' items-center'>{totalInActive}</p>
                                    )
                                }
                                
                            </div>
                        </div>
                    </Tooltip>
                    }
                </div>
                <div className='flex w-1/4 mt-10 flex-wrap '>
                    {
                        <Tooltip placement="bottom" title={activeUsers} color='green'>
                            <div
                                className=' flex flex-col bg-white border  border-blue-400 xl:px-5 lg:px-5 md:px-5 mr-5 mt-3 items-center justify-center pt-5 hover:'>
                                <h1 className=' items-center'  >
                                    InActive users
                                </h1>
                            <div className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></div>
                            <div className='dashboard_items_value'>
                                {
                                    isLoading || isFetching ? (<Spin indicator={antIcon} />) : (
                                        <p className=' items-center'>{totalActive}</p>
                                    )
                                }
                            </div>
                            </div>
                        </Tooltip>
                    }
                </div>


                <div className=' flex w-1/4 mt-10 flex-wrap'>
                    {
                        <div
                            className=' flex flex-col bg-white border  border-blue-400 xl:px-5 lg:px-5 md:px-5 mr-5 mt-3 items-center justify-center pt-5 hover:'>
                            <h1 className=''  >
                                Totoal User
                            </h1>
                            <div className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></div>
                            <div className='dashboard_items_value'>

                                {
                                    isLoading || isFetching ? (<Spin indicator={antIcon} />) : (
                                        <p className=' items-center'>{totalUsers}</p>
                                    )
                                }
                                
                            </div>
                        </div>
                    }
                </div>

                <div className=' flex w-1/4 mt-10 flex-wrap'>
                    {
                        <div
                            className=' flex flex-col bg-white border  border-blue-400 xl:px-5 lg:px-5 md:px-5 mr-5 mt-3 items-center justify-center pt-5 hover:'>
                            <h1 className=''  >
                                Crash Report
                            </h1>
                        <div className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></div>
                        <div className='dashboard_items_value'>

                            {
                                isLoading || isFetching ? (<Spin indicator={antIcon} />) : (
                                    <p className=' items-center'>{totalCrush}</p>
                                )
                            }
                            
                        </div>
                    </div>
                    }
                </div>
                </div>
            </div>

                <div className='flex items-center'>
                    <div className='flex items-center justify-center dot_style_graph'>
                        <div className='dot_style_inner'></div>
                    </div>
                    <p className='mt-3 px-3'>Growth Payment</p>
                </div>
                <div className='bg-white'>    
                {
                    dataGraphValue === "daily" ?
                        <DataGraphDaily  />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "weekly" ?
                    <DataGraphWeekly />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "monthly" ?
                    <DataGraphMonthly />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "yearly" ?
                    <DataGraphYearly />
                    :
                    <div>
                    </div>
                }
                </div>
                <div className='mt-10'>
                    <div className='flex p-1 w-full rounded-md items-center dashboard_header'>
                        <p className='text-md font-bold px-3 mt-2'> Total $950,444</p>
                    </div>
                    <div className='flex items-center'>
                        <div className='flex items-center justify-center dot_style_graph'>
                            <div className='dot_style_inner'></div>
                        </div>
                        <p className='mt-3 px-3'>Net Payment</p>
                    </div>
                </div>
                <div className="bg-white">
                {
                    dataGraphValue === "daily" ?
                        <DataGraphDaily  />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "weekly" ?
                    <DataGraphWeekly />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "monthly" ?
                    <DataGraphMonthly />
                    :
                    <div>
                    </div>
                }
                {
                    dataGraphValue === "yearly" ?
                    <DataGraphYearly />
                    :
                    <div>
                    </div>
                }
                </div>
                <div className='mt-10'>
                    <div className='flex p-1 w-full rounded-md items-center dashboard_header'>
                        <p className='text-md font-bold px-3 mt-2'> Total $124,800</p>
                    </div>
                </div>


        </Home>
    )
}

