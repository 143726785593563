import React, { useState } from 'react'
import { Button, Form, Input, message, Modal, Upload} from 'antd'
import {AiOutlinePlus} from 'react-icons/ai'
import { useUpdateProfileMutation } from '../../services/clients/clients.service'
import {toast} from 'react-toastify'
import { Buffer } from 'buffer';
import { FaUser } from 'react-icons/fa'


export const EditClientsModal = ({ visible, handleCancel, clients }) => {

    window.Buffer = Buffer;
    const [form] = Form.useForm();
    const [ setUpdateProfilePicture]=useState(null)

    const [updateProfile, { data, isLoading, isSuccess, isError, error }] = useUpdateProfileMutation()

    if (isSuccess && data) {
        toast.success("User updated Successfully!")
        setTimeout(() => {
            handleCancel()
        }, 3000);
    }
    const valuesClient ={
        fullName: clients?.fullName,
        email: clients?.email,
        street: clients?.address?.street,
        state: clients?.address?.state,
        zipCode: clients?.address?.zipCode,
        "SSN/EIN": clients?.address?.["SSN/EIN"],
        phoneNumber: clients?.phoneNumber,
    }

    const handleSubmit = (values) => {
        
        const email = clients?.email
        const profilePicture=clients?.profilePicture
        const updateValues={
            fullName: values?.fullName,
            email: values?.id,
            phoneNumber: values?.phoneNumber,
        }
        updateProfile({
            email,
            values:updateValues,
            profilePicture,
        })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        beforeUpload: file => {
            setUpdateProfilePicture(file)
            return false
        },

    }

    return (
        <Modal
            visible={visible}
            title="Edit clients"
            onOk={form.submit}
            onCancel={handleCancel}
            className='client_modal_style'
            confirmLoading={isLoading}
        >
            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }
            <div className='flex items-center justify-center mb-5'>
            {
                    clients?.profilePicture?
                    <div className="relative">
                        <img className="w-64 h-64 rounded-full" src={clients?.profilePicture} alt={clients?.fullName} />
                    </div>
                    : <div className="relative w-1/2">
                        <FaUser className='w-64 h-64 rounded-full' />
                    </div>
                }

                <Upload {...props} showUploadList={false} >
                    <Button
                        className='mt-10 -ml-6 client_upload_icon_test flex items-center justify-center'
                        >
                        <AiOutlinePlus className='w-6 h-6 -mx-2' type='upload' />
                    </Button>
                </Upload>
            </div>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='flex flex-col py-5 w-full'
                initialValues={valuesClient}
            >
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Full Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    
                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Phone Number!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Update
                        </Button>
                    </Form.Item>
            </Form>

        </Modal>
    )
}
