import React, { useState } from 'react'
import { message, Spin} from 'antd'
import moment from 'moment'
import { ConfirmDeleteModal } from '../shared/ConfirmDeleteModal'
import { EditClientsModal } from './EditClientsModal'
import { ToastContainer } from 'react-toastify'
import { LoadingOutlined } from '@ant-design/icons';
import { useGetUserCardInfoQuery } from '../../services/user/user.service'
import { FaUser } from 'react-icons/fa';

export const ClientsProfile = ({ clients,clientsId }) => {

    const response = useGetUserCardInfoQuery(clientsId)
    
    const { data, isLoading,isFetching } = response

    const cardInfo = data?.Items
    
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const [showModal, setShowModal] = useState({
        editClients: false,
        resetPassword: false,
        deleteClients: false,
    })

    const showEditModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editClients: true
        }))
    }

    const editModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editClients: false
        }))
    }

    const handleEditClientsSubmit = (values) => {
    }

    const handleEditClientsFailed = () => {
        message.error('Submit failed!');
    }
    const showDeleteModal = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteClients:  true
        }))
    }

    const deleteModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteClients: false
        }))
    }

    const handleDeleteClientsSubmit = (values) => {
    }

    return (
        <div className='flex flex-col w-full bg-white'>
            <ToastContainer />
            {
                showModal.editClients &&
                <EditClientsModal
                    visible={showModal.editClients}
                    handleCancel={editModalCancel}
                    handleSubmit={handleEditClientsSubmit}
                    handleFailed={handleEditClientsFailed}
                    clients={clients}
                />
            }
            {
                showModal.deleteClients &&
                <ConfirmDeleteModal
                    visible={showModal.deleteClients}
                    onCancel={deleteModalCancel}
                    handleOk={handleDeleteClientsSubmit}
                    title="Delete client"
                    okText="Delete"
                    warningText="Are you sure to delete this client"
                    user={clients}
                />
            }

            <div className='justify-center p-3 py-7 nav-text'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                        <p>Personal Information</p>
                    </div>
                    <div className='flex px-3 items-center'>
                        <p
                            className='px-2 cursor-pointer'
                            onClick={showEditModal}
                        >
                            Edit
                        </p>
                        <p className='text-red-800 px-2 cursor-pointer'
                            onClick={showDeleteModal}
                        >
                            Delete
                        </p>
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                {
                    clients?.profilePicture?
                    <div className="relative justify-center">
                        <img className="w-64 h-64 rounded-full" src={clients?.profilePicture} alt={clients?.fullName} />
                    </div>
                    : <div className="relative w-1/2">
                        <FaUser className='w-64 h-64 rounded-full' />
                    </div>
                }
                </div>
                
                <div className="relative overflow-x-auto bg-white">
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <tbody>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    User ID
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.userID}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Full Name
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.fullName}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Email
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.email}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Phone Number
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.phoneNumber}
                                </td>
                            </tr>
                            {/* <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    State
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.address?.state}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Street
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.address?.street}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Zip Code
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.address?.zipCode}
                                </td>
                            </tr>
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    SSN/EIN
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.address?.["SSN/EIN"]}
                                </td>
                            </tr> */}
                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Created At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(clients?.createdAt).format('l')
                                    }
                                </td>
                            </tr>

                            <tr className="bg-white border-b ">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Update At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(clients?.updatedAt).format('l')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='flex flex-row md:w-full items-center mt-3 flex-wrap'>
                        {
                            isLoading || isFetching ?
                            (<div className='flex'>
                                <Spin indicator={antIcon} />
                            </div>) :
                            (
                            cardInfo?.map(cardInfo => {
                                return (
                                    <div
                                        className='flex flex-col status_container mr-5 items-center mt-3 justify-center pt-5'>
                                        <h1 className='dashboard_header'>
                                            {cardInfo?.bankName}
                                        </h1>
                                        <p className='dashboard_items_value'>
                                            {cardInfo?.routingNumber}
                                        </p>
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


