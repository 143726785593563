import React, { useState } from 'react'
import {Dropdown, Menu} from 'antd'
import {CgDetailsMore} from 'react-icons/cg'
import {AiOutlineMore} from 'react-icons/ai'
import {userTypes} from '../../../common/UserType'
import { EditAdminLogModal } from './EditAdminLogModal'
import { useGetAdminLogDetailByIDQuery } from '../../../services/admin/admin.service'

export const AdminLogOptions = ({ record, handleEditModal, userType }) => {

    const userId = record?.id

    const [editVisible, setEditVisible]=useState(false)


    const response = useGetAdminLogDetailByIDQuery(userId)
    const { data } = response
    const admin = data?.data

    const handleDetail = () => {
        setEditVisible(true)
    }

    const handleCancel = () => {
        setEditVisible(false)
    }

    const AdminLogs={
        id: admin?.id,
        logType: admin?.logType,
        title: admin?.title,
        description:{
            note: admin?.description?.note
        }
    }

    const menu = (
        <Menu style={{ width: 140 }}>
            {
                userType === userTypes.ADMIN &&
                <div>
                    <Menu.Item key="1">
                        <div className='flex items-center' onClick={handleDetail}>
                            <CgDetailsMore />
                            <span className='ml-2'>
                                Edit
                            </span>
                        </div>
                    </Menu.Item>
                </div>
            }
            <Menu.Divider />
        </Menu>
    )

    return (
        <div className='flex flex-col'>
            {
                editVisible &&
                <EditAdminLogModal
                    visible={editVisible}
                    handleCancel={handleCancel}
                    admin={AdminLogs}
                />
            
            }
            <div className='flex flex-col cursor-pointer w-full items-center justify-center'>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" key={userId}>
                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <AiOutlineMore className='w-7 h-7' />
                    </span>
                </Dropdown>
            </div>
        </div>
    )
}
