
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Tabs } from 'antd';
import { Home } from '../Home';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetWasherByIDQuery } from '../../services/washer/washer.service';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { WasherProfile } from './WasherProfile';
import { ServiceHistory2 } from './serviceHistory/ServiceHistory2';
import { PaymentData } from './payments/PaymentData';
import { FaUser } from 'react-icons/fa';


export const WasherDetail = () => {

    const { TabPane } = Tabs;
    const navigate = useNavigate()

    const { washerId } = useParams()

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const response = useGetWasherByIDQuery(washerId)
    const  {  data,isLoading, isSuccess, isFetching } = response
    const washer=data?.data

    const callback = (key) => {
    }

    const back = () => {
        navigate('/washer')
    }

    return (
        <Home>
            <div className='flex m-2 px-3 w-full'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
                {
                    isSuccess &&
                    <div className='flex items-center mx-3 px-3'>
                    {
                        washer?.profilePicture?
                        <div className="relative">
                            <img className="w-11 h-11 rounded-full" src={washer?.profilePicture} alt={washer?.fullName} />
                        </div>
                        : <div className="relative w-1/2">
                            <FaUser className='w-11 h-11 rounded-full' />
                        </div>
                    }
                        <p className='self-center mt-3 pl-2 text-md font-bold'>{washer?.fullName}</p>
                    </div>
                }
            </div>
            {
                isLoading || isFetching ?
                    (<div className='flex'>
                        <Spin indicator={antIcon} />
                    </div>) :
                    (
                        <div className='flex flex-col '>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Profile" key="1">
                                <WasherProfile washer={washer} washerId={washerId} />
                            </TabPane>
                            <TabPane tab="Service History" key="2">
                                <ServiceHistory2 />
                            </TabPane>
                            <TabPane tab="Payments" key="3">
                                <PaymentData />
                            </TabPane>
                        </Tabs>
                        </div>
                    )
            }
        </Home>
    )
}
