import { Input, TreeSelect } from 'antd';
import React, { useState } from 'react'
import moment from 'moment'
import { useGetServiceDataQuery } from '../../../services/service/services.service'; 
import { ClientsDataTable } from '../../clients/ClientsDataTable'; 
import { AddServiceCatagory } from './AddServiceCatagory';
import { userTypes } from '../../../common/UserType';
import { ServiceOption } from './ServiceOption';

export const CatagoryService = (catagoryData) => {
    const { Search } = Input;
    const CatagoryData = catagoryData?.catagoryData 

    const [ visible, setVisible ] = useState(false)
    const [ searchText, setSearchText ] = useState('')
    const [ filteredResults, setFilteredResults] = useState([]);
    const [ setDataSort ] = useState([]);
    const [ order, setOrder ] = useState("ACS");
    const [valueSelect, setValueSelect] = useState()


    const response = useGetServiceDataQuery(valueSelect)

    const { data, isError, isFetching,isLoading, isSuccess, error } = response

    const _data = data?.data?.Items

    const onChange = (newValue) => {
      setValueSelect(newValue);
    };
    const onSearch = (value) => {
        setSearchText(value)
         if(value !== '' ){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
            setDataSort(filteredData)
        }
        else{
            setFilteredResults(_data)
            setDataSort(_data)
        }
    }
  
    const handleCancel = () => {
        setVisible(false)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
    {
        key: "title",
        title: "Title",
        dataIndex: "title",
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("title")
                }
            };
        },
    }, 
    {
        key: "subtitle",
        title: "Subtitle",
        dataIndex: 'subtitle',
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                    Sorting("name")
                }
            };
        },
    }, 

    {
        key: "description",
        title: "Description",
        dataIndex: 'description',
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("description")
                }
            };
        },
    },
    {
        key: "amount",
        title: "Amount",
        dataIndex: 'amount',
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("amount")
                }
            };
        },
    },
    {
        key: "createdBy",
        title: "Created By",
        dataIndex: "createdBy",
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("createdBy")
                }
            };
        },
    },
    {
        key: "createdAt",
        title: "Date Added",
        dataIndex: "createdAt",
        render: (createdAt) => (
            <div>
                {
                    moment(createdAt).format('L')
                }
            </div>
        ),
        onHeaderCell: (column) => {
            return {
                onClick: () => {
                Sorting("createdAt")
                }
            };
        },
    },
    {
        key: "userID",
        title: "Action",
        render: (record) => 
        <div>
            <ServiceOption
                record={record}
                userType={userTypes.CLIENTS}
            />
        </div>
        
    },
    ]

    const showModal = () => {
        setVisible(true)
    }

    return (
        <>
            <div className='flex flex-col'>
                {
                   visible && <AddServiceCatagory visible={visible} handleCancel={handleCancel}/>
                }
                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch}
                        />
                    </div>

                    <div className="relative flex flex-col border-white lg:w-1/6 md:w-1/6 xl:w-1/6">
                        <div className='flex w-full'>
                            <p>Select Catagory</p>
                        </div>
                        <div className='w-full flex '>
                        <TreeSelect
                            style={{
                                width: '100%',
                            }}
                            value={valueSelect}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeData={CatagoryData?.map( (data,index) => ({
                                    title : data?.name,
                                    value : data?.id,
                                })
                            )}
                            placeholder="Please select"
                            treeDefaultExpandAll
                            onChange={onChange}
                        />
                        </div>
                    </div>

                    <div className='mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add Service Catagory
                        </button>
                    </div>
                </div>

                <ClientsDataTable
                    key={_data}
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </>
    )
}

