import React from 'react'
import {Form, Input, message, Modal} from 'antd'
import { useUpdateAdminLogMutation } from '../../../services/user/user.service'
import {toast} from 'react-toastify'


export const EditAdminLogModal = ({ visible, handleCancel, admin }) => {

    const [form] = Form.useForm();

    const [updateProfile, { data, isLoading, isSuccess, isError, error }] = useUpdateAdminLogMutation()

    if (isSuccess && data) {
        toast.success("User updated Successfully!")
        setTimeout(() => {
            handleCancel()
        }, 3000);
    }

    const handleSubmit = (values) => {
        const email = admin.id
        updateProfile({
           email,
           values
        })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <Modal
            visible={visible}
            title="Edit admin"
            onOk={form.submit}
            onCancel={handleCancel}
            className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style '
            confirmLoading={isLoading}
        >

            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='flex flex-col py-5 w-full'
                initialValues={admin}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your type!',
                        },
                    ]}
                >
                    <Input placeholder="Title" />
                </Form.Item>

                <Form.Item
                    name="logType"
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your phone number!',
                        },
                    ]}
                >
                    <Input placeholder="Phone Number" />
                </Form.Item>

                <Form.Item
                    name='note'
                    label="Note"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your note!',
                        },
                    ]}
                >
                    <Input placeholder="Note" />
                </Form.Item>

            </Form>
        </Modal>
    )
}
