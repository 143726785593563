
import React, {  useState } from 'react'
import { Table } from 'antd';
import {  useParams } from 'react-router-dom';
import { EditClientsModal } from './EditClientsModal';

export const ClientsDataTable = ({_data,columns, isLoading, isError,  error }) => {

    const [showEditModal, setShowEditModal] = useState(false)
    const [sortValue] = useState('')
    const [ filteredResults] = useState([]);
    const { clientsId } = useParams()

    const cancelEditModal = () => {
        setShowEditModal(false)
    }

    return (
        <div className='flex flex-col'>
            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data?.message}
                    </p>
                </div>
            }
            {
                showEditModal && <EditClientsModal showEditModal={showEditModal} cancelEditModal={cancelEditModal} userId={clientsId} />
            }

            <div>
                <EditClientsModal showEditModal={showEditModal} cancelEditModal={cancelEditModal} userId={clientsId} />
            </div>
            <div className='mt-8 w-full overflow-x-auto'>
                <Table
                    key={_data}
                    dataSource={sortValue ?filteredResults: _data}
                    columns={columns}
                    pagination={true}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}
