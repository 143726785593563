import React, { useEffect } from 'react'

import { Modal } from 'antd'
import { useDeleteRequestMutation, useDeleteServiceMutation } from '../../services/service/services.service' 
import { toast } from 'react-toastify'

export const ConfirmDeleteService = ({ visible, title, onCancel, okText, user }) => {

    const [deleteUser, { isLoading, isSuccess, isError, error }] = useDeleteServiceMutation()
    const [deleteRequest] = useDeleteRequestMutation()
    const id=user?.id
   
    const handleOk = () => {
        deleteUser(id)
        deleteRequest(id)
    }

    useEffect (() => {
        if(isSuccess ){
            toast.success("User Deleted Successfully!")
            setTimeout(() => {
            }, 3000);
        }
    })

    return (
        <Modal
            visible={visible}
            title={title}
            onOk={handleOk}
            onCancel={onCancel}
            okText={okText}
            className='user_modal_style'
            confirmLoading={isLoading}
        >

            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }

            <p>
                Are you sure, you want to delete {user?.name}
            </p>
            <p>This will be deleted so soon</p>
        </Modal>
    )
}
