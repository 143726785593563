
import React, { useState } from 'react'

import {  Input } from 'antd'
import { AdminDataTable } from '../AdminDataTable'; 
import { useGetAllAccessLevelQuery } from '../../../services/admin/admin.service'; 
import moment from 'moment'
import { userTypes } from '../../../common/UserType'; 
import { AddAccessLevel } from './AddAccessLevel';
import { AccessLevelOption } from './AcessLevelOption';


export const AccessLevelAll = () => {
    const { Search } = Input;

    const [visible, setVisible] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const [order, setOrder] = useState("ACS");

    const response = useGetAllAccessLevelQuery()

    const { data, isError, isFetching, isLoading, isSuccess, error } = response

    const _data = data?.data

    const onSearch = (value) => {
        setSearchText(value)
        if(value !== ''){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(_data)
        }
    }


    const handleCancel = () => {
        setVisible(false)
    }
    
    const showModal = () => {
        setVisible(true)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }


    const columns = [
        {
            key: "email",
            title: "Email",
            dataIndex: "id",
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("email")
                  }
                };
            }
        },
        {
            key: "isView",
            title: "Is View",
            dataIndex: "isView",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "isDelete",
            title: "Is Delete",
            dataIndex: "isDelete",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "isEdit",
            title: "Is Edit",
            dataIndex: "isEdit",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("createdAt")
                  }
                };
            },
        },
        {
            key: "lastLogIn",
            title: "Last log in",
            dataIndex: "updatedAt",
            render: (updatedAt) => (
                <div>
                    {
                        moment(updatedAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("updatedAt")
                  }
                };
            },
        },
        {
            key: "userID",
            title: "Action",
            render: (record) => 
            <div>
                <AccessLevelOption
                    record={record}
                    userType={userTypes.CLIENTS}
                />
            </div>
            
        },

    ]
  
    return (
        <>
            <div className='flex flex-col'>
                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-full'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch} />
                    </div>
                    {
                        visible && <AddAccessLevel visible={visible} handleCancel={handleCancel} />
                    }
                    <div className='mt-4 md:justify-end'>
                        <button
                            onClick={showModal}
                            className='add_user_button px-7 py-1'>
                            Add
                        </button>
                    </div>
                </div>

                <AdminDataTable
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </>    
    )
}

