import React, { useEffect, useState } from 'react'

import { Menu, Dropdown } from 'antd'

import { CgDetailsMore } from 'react-icons/cg'
import { AiOutlineMore } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { ConfirmDeleteService } from '../../shared/ConfirmDeleteService'
import { EditCatagory } from './EditCatagory'
import { useUpdateServiceMutation } from '../../../services/service/services.service' 

export const CatagoryOption = ({ record, userType,DeleteOption }) => {
    
    const id = record.id

    const [visible, setVisible]=useState(false)
    const [editVisible, setEditVisible]=useState(false)

    const [ data, isSuccess ] = useUpdateServiceMutation()

    useEffect(()=>{
        if (isSuccess && data) {
            toast.success("update")
            setTimeout(() => {
            }, 3000);
        }   
    })

    const handleActivate = () => {
        setEditVisible(true)
    }

    const handleVerify = () =>{
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
        setEditVisible(false)
    }

    const menu = (
        <Menu style={{ width: 140 }}>
            {
                <div>
                    <Menu.Item key="1">
                        <div className='flex items-center' >
                            <div className='flex flex-col'>
                                <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                    <CgDetailsMore />
                                    <button onClick={handleActivate}>
                                        Edit
                                    </button>
                                </div>
                                <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                    <CgDetailsMore />
                                    <button onClick={handleVerify}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                </div>
            }
            <Menu.Divider />
        </Menu>
    )

    return (

        <div className='flex flex-col'>
            {
                visible &&
                <ConfirmDeleteService
                    visible={visible}
                    onCancel={handleCancel}
                    title="Delete client"
                    okText="Delete"
                    warningText="Are you sure to delete this client"
                    user={record}
                />                
            }
            {
                editVisible &&
                <EditCatagory
                    visible={editVisible}
                    handleCancel={handleCancel}
                    clients={record}
                />
            
            }

            <div className='flex flex-col cursor-pointer w-full items-center justify-center' >
                <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" key={id}>
                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <AiOutlineMore className='w-7 h-7' />
                    </span>
                </Dropdown>
            </div>
        </div>
    )
}
