
import React, { useEffect } from 'react'
import { Modal, Button, Form, Input, message} from 'antd'
import { toast, ToastContainer } from 'react-toastify'
import { useAddCatagoryMutation } from '../../../services/service/services.service'


export const AddCatagory = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();

    const [addCatagory, { data, isLoading, isSuccess, isError, error }] = useAddCatagoryMutation()

    useEffect(()=>{
        if (isSuccess && data?.success === true) {
                toast.success(data?.message , {
                    toastId: "success"
                })
                setTimeout(() => {
                    handleCancel()
                }, 3000);
            }
        if (isError) {
            toast.error(error?.data.data, {
                toastId: "successCatagory"
            })
            setTimeout(() => {
            }, 3000);
        }    
    })

    const handleSubmit = async (values) => {
        try {
                const Catagory={
                    name: values.name,
                    description: values.description,
                }

                const body = {
                    ...Catagory
                }

                await addCatagory(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Descrption"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your description!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Add Catagory
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
