

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper,providesTagsHelperObject } from '../providesTagsHelper'


const tagType = "Setting"


export const settingApi = createApi({
    reducerPath: "settingApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        addServiceType: builder.mutation({
            query: body => ({
                url: 'settings/add',
                method: 'POST',
                body
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        getSettingByID: builder.query({
            query: (settingId) => `settings/${settingId}`,
            providesTags: (result) => providesTagsHelperObject(result, tagType, "SETTING"),
        }),
        editServiceType: builder.mutation({
            query: (body) => ({
                url: `settings/serviceType/${body.id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        getSettingsServiceType: builder.mutation({
            query: (body) => ({
                url: `settings/all`,
                method: 'GET',
                body
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        AddSetting: builder.mutation({
            query: body => ({
                url: 'settings/add',
                method: 'POST',
                body
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        
        updateSetting: builder.mutation({
            query: body => ({
                url: `settings/${body.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: ['CLIENT', 'Epo', 'User'],
        }),
        getSettings: builder.query({
            query: () => 'settings/all',
            providesTags: (result) => providesTagsHelper(result, tagType, "SETTING"),
        }),
        deleteSetting: builder.mutation({
            query: clientsId => ({
                url: `settings/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'CLIENT' }],
        }),
    })
})


export const { 
    useAddServiceTypeMutation,
    useEditServiceTypeMutation,
    useGetSettingsServiceTypeMutation,
    useAddSettingMutation,
    useGetSettingsQuery,
    useGetSettingByIDQuery,
    useUpdateSettingMutation,
    useDeleteSettingMutation,
 } = settingApi



