import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAccessLevelByIdQuery } from '../../../services/admin/admin.service'
import moment from 'moment'
import { AdminDataTable } from '../AdminDataTable'
import { AccessLevelOption } from './AcessLevelOption'
import { userTypes } from '../../../common/UserType'

export const AccessLevelDataTable = () => {
  
    const { adminId } = useParams()

    const response = useGetAccessLevelByIdQuery(adminId)

    const { data, isError, isFetching, isLoading, isSuccess, error } = response

    const _data = data?.data?.Items

    const columns = [
        {
            key: "isView",
            title: "Is View",
            dataIndex: "isView",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "isDelete",
            title: "Is Delete",
            dataIndex: "isDelete",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "isEdit",
            title: "Is Edit",
            dataIndex: "isEdit",
            render: (record) => record ? <p>Yes</p> : <p>N0</p> 
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
        },
        {
            key: "lastLogIn",
            title: "Last log in",
            dataIndex: "updatedAt",
            render: (updatedAt) => (
                <div>
                    {
                        moment(updatedAt).format('L')
                    }
                </div>
            ),
        },
        {
            key: "userID",
            title: "Action",
            render: (record) => 
            <div>
                <AccessLevelOption
                    record={record}
                    userType={userTypes.CLIENTS}
                />
            </div>
            
        },
    
    ]

    return (
        <div>
            <AdminDataTable
                _data={ _data}
                columns={columns}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                error={error}
                isSuccess={isSuccess}
            />
        </div>
    )
    }
