
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper } from '../providesTagsHelper'

const tagType= "SERVICE"

export const serviceUserApi = createApi({
    reducerPath: "serviceUserApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getServiceByEmail: builder.query({
            query: (clientsId) => `request/requests/byEmail/${clientsId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        getServiceByID: builder.query({
            query: (settingId) => `service/${settingId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        getServiceHistoryDetailByID: builder.query({
            query: (settingId) => `request/${settingId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        AddService: builder.mutation({
            query: body => ({
                url: 'service/add',
                method: 'POST',
                body
            }),
        }),
        updateService: builder.mutation({
            query: body => ({
                url: `service/category/${body.id}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'SERVICE' },'SERVICE', 'WASHER', 'ADMIN'],
        }),
        updateCatagoryService: builder.mutation({
            query: body => ({
                url: `service/${body.id}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'SERVICE' },'SERVICE', 'WASHER', 'ADMIN'],
        }),
        updateAddtionalService: builder.mutation({
            query: body => ({
                url: `service/additionalService/${body.id}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'SERVICE' },'SERVICE', 'WASHER', 'ADMIN'],
        }),

        getServiceManager: builder.query({
            query: () => 'service/all',
        }),
        deleteService: builder.mutation({
            query: clientsId => ({
                url: `service/category/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        deleteRequest: builder.mutation({
            query: clientsId => ({
                url: `request/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        deleteServiceCatagory: builder.mutation({
            query: clientsId => ({
                url: `service/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        deleteAddtionalService: builder.mutation({
            query: clientsId => ({
                url: `service/additionalService/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }],
        }),

        getCatagory: builder.query({
            query: (clientsId) => `service/categories/services`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        getServiceCatagory: builder.query({
            query: (clientsId) => `service/services/${clientsId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        getServiceData: builder.query({
            query: (clientsId) => `service/services/${clientsId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        getAddtionalService: builder.query({
            query: (clientsId) => `service/additionalServices/${clientsId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"SERVICE"),
        }),
        AddCatagory: builder.mutation({
            query: body => ({
                url: 'service/category',
                method: 'POST',
                body
            }),
            invalidatesTags: [{ type: tagType },'SERVICE'],
        }),
        AddServiceCatagory: builder.mutation({
            query: body => ({
                url: `service/${body?.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{ type: tagType },'SERVICE'],
        }),
        AddAdtionalService: builder.mutation({
            query: body => ({
                url: `service/additionalService/${body?.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{ type: tagType },'SERVICE'],
        }),
    })
})


export const { 
    useGetServiceByEmailQuery, 
    useGetCatagoryQuery, 
    useGetServiceDataQuery,
    useGetAddtionalServiceQuery,
    useGetServiceCatagoryQuery, 
    useAddServiceMutation,
    useAddCatagoryMutation,
    useAddServiceCatagoryMutation,
    useAddAdtionalServiceMutation,
    useGetServiceManagerQuery,
    useGetServiceByIDQuery,
    useGetServiceHistoryDetailByIDQuery,
    useUpdateServiceMutation,
    useUpdateCatagoryServiceMutation,
    useUpdateAddtionalServiceMutation,
    useDeleteServiceMutation,
    useDeleteRequestMutation,
    useDeleteServiceCatagoryMutation,
    useDeleteAddtionalServiceMutation,
} = serviceUserApi



