
import React, { useState } from 'react'
import { Table } from 'antd';
import { EditWasherModal } from './EditWasherModal';

export const WasherDataTable = ({_data, columns, isLoading, isError, error }) => {

    const [showEditModal, setShowEditModal] = useState(false)
    const [userId] = useState('')

    const cancelEditModal = () => {
        setShowEditModal(false)
    }

    return (
        <div className='flex flex-col'>
            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }

            {
                showEditModal && <EditWasherModal showEditModal={showEditModal} cancelEditModal={cancelEditModal} userId={userId} />
            }

            <div className='mt-8 w-full overflow-x-auto'>
                <Table
                    dataSource={_data}
                    columns={columns}
                    pagination={true}
                    loading={isLoading}
                />
            </div>

        </div>
    )
}
