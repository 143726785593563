import React, { useEffect } from 'react'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { MainRoutes } from './routes/MainRoutes';
import ReactGA from 'react-ga';


function App() {

  useEffect(()=>{
    ReactGA.initialize('G-2BLMYKFBGZ');
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  return (
    <MainRoutes />
  );
}

export default App;
