
import React from 'react'

import { IoIosArrowBack } from 'react-icons/io'
import { Tabs } from 'antd';
import { Home } from '../../Home'; 
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DetailRequest } from './DetailRequest';
import { useGetServiceHistoryDetailByIDQuery } from '../../../services/service/services.service';

export const ServiceHistoryDetail = () => {
    const { TabPane } = Tabs;
    const navigate = useNavigate()
    
    const { serviceHistoryId } = useParams()
    
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const reponse = useGetServiceHistoryDetailByIDQuery(serviceHistoryId)
    const  {  data,isLoading,isFetching } = reponse

    const clients = data 

    const callback = async (key) => {
    }

    const back = () => {
        navigate(-1)
    }

    return (
        <Home>
            <div className='flex m-2 px-3 w-full'>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
            </div>

            {
                isLoading || isFetching ?
                    (<div className='flex'>
                        <Spin indicator={antIcon} />
                    </div>) :
                    (
                        <div className='flex   '>  {/*lg:ml-40*/}
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Detail request" key="1">
                                <DetailRequest clients={clients} clientsId={serviceHistoryId}  />
                            </TabPane>
                        </Tabs>
                        </div>
                    )
            }
        </Home>
    )
}
