
import React, {  useState } from 'react'
import { Button, Form, Input } from 'antd'
import { Home } from '../Home';
import { useChangePasswordMutation } from '../../services/user/user.service';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io'


export const ChangePassword = () => {

    const [form] = Form.useForm();
    const [passwordNotMatch, setPasswordNotMatch] = useState("")
    const [changePassword, { data, isLoading, isSuccess, isError, error }] = useChangePasswordMutation()
    
    const back = () => {
        navigate('/profile')
    }

    const navigate = useNavigate()
        if(isSuccess){
            const message = data.message
            toast.success(message)
            setTimeout(() => {
                navigate("/profile")
            }, 5000);
        } 

    const handleSubmit =  (values) => {
        const newPassword = values.newPassword
        const currentPassword = values.currentPassword
        const confirmPassword=values.confirmPassword

        if (newPassword !== confirmPassword) {
            return setPasswordNotMatch("Confirm password doesn't match ")
        }
        setPasswordNotMatch("")

        const body = {currentPassword: currentPassword, newPassword: newPassword }
        changePassword({body})
    };
   
    const onFinishFailed = () => {
    };

    return (
        <Home>

            <ToastContainer />
            <div>
                <IoIosArrowBack onClick={back} className='cursor-pointer' />
            </div>
            <div className='flex items-center justify-center w-full p-5'>
                <div className='md:w-1/2 w-full shadow-xl p-5'>
                    {
                        passwordNotMatch &&
                        <p className='text-red-500 text-md font-bold my-4'>{passwordNotMatch}</p>
                    }

                    {
                        isError &&
                        <div className='flex mt-3 justify-center'>
                            <p className='text-red-500 text-md font-bold'>
                                {error?.message || error?.data.message}
                            </p>
                        </div>
                    }

                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='flex flex-col'
                    >
                        <Form.Item
                            name="currentPassword"
                            label="Current password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input current password!',
                                },
                            ]}
                        >
                            <Input type="password" placeholder="Current Password" />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="New password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password!',
                                },
                            ]}
                        >
                            <Input type="password" placeholder="New Password" />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirm password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your confirm password!',
                                },
                            ]}
                        >
                            <Input type="password" placeholder="******" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                loading={isLoading}
                                htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>

                    </Form>
                </div>

            </div>
        </Home>
    )
}
