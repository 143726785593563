
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, message, TreeSelect } from 'antd'
import { toast, ToastContainer } from 'react-toastify'
import { useAddServiceCatagoryMutation, useGetCatagoryQuery } from '../../../services/service/services.service' 


export const AddServiceCatagory = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();

    const [valueSelect, setValueSelect] = useState("")

    const response = useGetCatagoryQuery()
    const { data } = response
    const _data = data?.data

    const [addServiceCatagory, { isLoading, isSuccess, isError, error }] = useAddServiceCatagoryMutation()

    useEffect(()=>{
        if (isSuccess) {
                toast.success("service catagory created successfuly!", {
                    toastId: "success"
                })
                setTimeout(() => {
                    handleCancel()
                }, 3000);
            }
        if (isError) {
            toast.error(error?.data.data)
            setTimeout(() => {
            }, 3000);
        }    
    })

    const onChange = (newValue) => {
        setValueSelect(newValue);
    };
  
    const handleSubmit = async (values) => {
        try {
                const id= valueSelect
                const serviceCatagory={
                    title: values.title,
                    subtitle: values.subtitle,
                    amount: values.amount,
                    serviceName: values.serviceName,
                    description: values.description,
                }

                const body = {
                    id: id,
                    ...serviceCatagory
                }

                await addServiceCatagory(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    return (
        <>
            <ToastContainer />

            <Modal
                open={visible}
                title="Add Sevice Catagory"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style'
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    <Form.Item>
                        <div className="relative flex flex-col border-white w-full ">
                            <div className='flex w-full'>
                                <p>Select Catagory</p>
                            </div>
                            <div className='w-full flex '>
                            <TreeSelect
                                style={{
                                    width: '100%',
                                }}
                                value={valueSelect}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={_data?.map( (data,index) => ({
                                        title : data?.name,
                                        value : data?.id,
                                    })
                                )}
                                placeholder="Please select"
                                treeDefaultExpandAll
                                onChange={onChange}
                            />
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Subtitle"
                        name="subtitle"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your subtitle!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your amount!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Service Name"
                        name="serviceName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Service Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your description!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Add Catagory
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
