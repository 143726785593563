
import React from 'react'
import { Modal, Button, Form, Input, message, Upload } from 'antd'
import {  AiOutlinePlus } from 'react-icons/ai'
import { useUpdateProfileMutation } from '../../services/washer/washer.service'
import { toast } from 'react-toastify'

export const EditWasherModal = ({ visible, handleCancel, washer }) => {

    const [form] = Form.useForm();

    const [updateProfile, { data, isLoading, isSuccess, isError, error }] = useUpdateProfileMutation()

    if (isSuccess && data) {
        toast.success("User updated Successfully!")
        setTimeout(() => {
            handleCancel()
        }, 3000);
    }

    const valuesWasher ={
        fullName: washer?.fullName,
        email: washer?.email,
        street: washer?.address?.street,
        state: washer?.address?.state,
        zipCode: washer?.address?.zipCode,
        "SSN/EIN": washer?.address?.["SSN/EIN"],
        phoneNumber: washer?.phoneNumber,
    }

    const handleSubmit = (values) => {
        const updateValues={
            fullName: values?.fullName,
            email: values?.id,
            phoneNumber: values?.phoneNumber,
            address: {
                state: values?.state,
                street: values?.street,
                zipCode: values?.zipCode,  
                "SSN/EIN": values?.["SSN/EIN"]  
            }
        }
        const email = washer?.email
        updateProfile({
           email,
           values: updateValues 
        })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
    }

    return (
        <Modal
            open={visible}
            title="Edit washer"
            onOk={form.submit}
            onCancel={handleCancel}
            className='client_modal_style'
            confirmLoading={isLoading}
        >
            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }
            <div className='flex items-center justify-center mb-5'>
            <div className="relative">
                <img className="w-64 h-64 rounded-full" src={washer?.profilePicture} alt={washer?.fullName} />
            </div>
            <Upload {...props} showUploadList={false} >
                <Button
                    className='mt-10 -ml-6 client_upload_icon_test flex items-center justify-center'
                    >
                    <AiOutlinePlus className='w-6 h-6 -mx-2' type='upload' />
                </Button>
            </Upload>

            </div>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='flex flex-col py-5 w-full'
                initialValues={valuesWasher}
            >
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Full Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    
                    <Form.Item
                        label="Street"
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Street!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your State!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    
                    <Form.Item
                        label="Zipcode"
                        name="zipCode"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Zipcode!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="SSN/EIN"
                        name="SSN/EIN"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your SSN/EIN!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Phone Number!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Update
                        </Button>
                    </Form.Item>
            </Form>
        </Modal>
    )
}
