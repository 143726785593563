
import React, { useState } from 'react'
import {  Input } from 'antd'
import { AdminDataTable } from './AdminDataTable';
import { useGetAllAdminLogQuery } from '../../services/admin/admin.service';
import moment from 'moment'
import { userTypes } from '../../common/UserType';
import { AdminLogOptions } from './AdminLog/AdminLogOptions';
import { AddAdminLog } from './AdminLog/AddAdminLog';

export const AdminLogAccessL = () => {
    const { Search } = Input;

    const [searchText, setSearchText] = useState('')
    const [filteredResults, setFilteredResults] = useState([]);
    const [order, setOrder] = useState("ACS");
    const [ setShowEditModal] = useState(false)
    const [setUserId] = useState('')
    const [visible, setVisible] = useState(false)

    const response = useGetAllAdminLogQuery()

    const { data, isError, isFetching, isLoading, isSuccess, error } = response

    const _data = data?.data

    const onSearch = (value) => {
        setSearchText(value)
        if(value !== ''){
            const filteredData = _data.filter((item) => {
                return Object.values(item).join('').toLocaleLowerCase().includes(value.toLocaleLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(_data)
        }
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleEditModal = (id) => {
        setShowEditModal(true)
        setUserId(id)
    }

    const Sorting = (value) => {
        let sortedData;
        const field= value
        if (order === 'ACS') {
            sortedData = [..._data].sort((a, b) => {
            return a[field].localeCompare(b[field]);
            });
            setOrder("DCS")
        } else if (order === 'DCS') {
            sortedData = [..._data].sort((a, b) => {
                return b[field].localeCompare(a[field]);
            })
            setOrder("ACS")
        } else {
            return _data;
        }
        setSearchText("sort")
        setFilteredResults(sortedData)
    }

    const columns = [
        {
            key: "title",
            title: "Title",
            dataIndex: "title",
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("title")
                  }
                };
            }
        },
        {
            key: "email",
            title: "Email",
            dataIndex: "email",
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("email")
                  }
                };
            }
        },
        {
            key: "createdAt",
            title: "Date Added",
            dataIndex: "createdAt",
            render: (createdAt) => (
                <div>
                    {
                        moment(createdAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("createdAt")
                  }
                };
            },
        },
        {
            key: "lastLogIn",
            title: "Last log in",
            dataIndex: "updatedAt",
            render: (updatedAt) => (
                <div>
                    {
                        moment(updatedAt).format('L')
                    }
                </div>
            ),
            onHeaderCell: (column) => {
                return {
                  onClick: () => {
                    Sorting("updatedAt")
                  }
                };
            },
        },
        {
            key: "userID",
            title: "Action",
            render: (text, record) => <AdminLogOptions
                record={record}
                handleEditModal={handleEditModal}
                userType={userTypes.ADMIN}
            />
        },
    ]
    
    return (
        <>
            <div className='flex flex-col'>
                {
                    visible && <AddAdminLog visible={visible} handleCancel={handleCancel} />
                }

                <div className='flex items-center justify-between flex-wrap-reverse'>
                    <div className='mt-4 md:w-1/3 w-1/2'>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch} />
                    </div>
                </div>

                <AdminDataTable
                    _data={ searchText ? filteredResults: _data}
                    columns={columns}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    error={error}
                    isSuccess={isSuccess}
                />
            </div>
        </>
    )
}

