import React from 'react'

import { Button, Form, Input, message, Modal} from 'antd'
import {toast} from 'react-toastify'
import { Buffer } from 'buffer';
import { useUpdateCatagoryServiceMutation } from '../../../services/service/services.service'


export const EditService = ({ visible, handleCancel, clients }) => {

    window.Buffer = Buffer;
    const [form] = Form.useForm();

    const [updateCatagoryService, { data, isLoading, isSuccess, isError, error }] = useUpdateCatagoryServiceMutation()

    if (isSuccess && data) {
        toast.success("User updated Successfully!")
        setTimeout(() => {
            handleCancel()
        }, 3000);
    }
    const valuesClient ={
        title: clients.title,
        subtitle: clients.subtitle,
        amount: clients.amount,
        serviceName: clients.serviceName,
        description: clients.description,
    }

    const handleSubmit = (values) => {
        
        const id = clients.id
        const updateValues={
            title: values.title,
            subtitle: values.subtitle,
            amount: values.amount,
            serviceName: values.serviceName,
            description: values.description,
        }
        updateCatagoryService({
            id,
            values:updateValues,
        })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <Modal
            open={visible}
            title="Edit clients"
            onOk={form.submit}
            onCancel={handleCancel}
            className='client_modal_style'
            confirmLoading={isLoading}
        >
            {
                isError &&
                <div className='flex mt-3'>
                    <p className='text-red-500 text-md font-bold mx-3'>
                        {error?.name || error?.status}
                    </p>
                    <p className='text-red-500 text-md font-bold'>
                        {error?.message || error?.data.message}
                    </p>
                </div>
            }

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='flex flex-col py-5 w-full'
                initialValues={valuesClient}
            >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Subtitle"
                        name="subtitle"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your subtitle!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your amount!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Service Name"
                        name="serviceName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Service Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your description!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                    >
                        <Button type="primary"
                        
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Update
                        </Button>
                    </Form.Item>
            </Form>

        </Modal>
    )
}
