import React, { useState } from 'react'

import { message, Spin} from 'antd'
import moment from 'moment'
import { ConfirmDeleteModal } from '../../shared' 
import { EditClientsModal } from '../EditClientsModal' 
import { ToastContainer } from 'react-toastify'
import { LoadingOutlined } from '@ant-design/icons';
import { useGetUserCardInfoQuery } from '../../../services/user/user.service' 

export const DetailRequest = ({ clients,clientsId }) => {

    const response = useGetUserCardInfoQuery(clientsId)
    
    const { data1, isLoading,isFetching } = response

    const cardInfo = data1?.Items
    
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const [showModal, setShowModal] = useState({
        editClients: false,
        resetPassword: false,
        deleteClients: false,
    })

    const editModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            editClients: false
        }))
    }

    const handleEditClientsSubmit = (values) => {
    }

    const handleEditClientsFailed = () => {
        message.error('Submit failed!');
    }
    
    // const showDeleteModal = () => {
    //     setShowModal(prevModal => ({
    //         ...prevModal,
    //         deleteClients:  true
    //     }))
    // }

    const deleteModalCancel = () => {
        setShowModal(prevModal => ({
            ...prevModal,
            deleteClients: false
        }))
    }

    const handleDeleteClientsSubmit = (values) => {
    }


    return (
        <div className='flex flex-col w-full justify-center xl:ml-60 bg-white'>
            <ToastContainer />
            {
                showModal.editClients &&
                <EditClientsModal
                    visible={showModal.editClients}
                    handleCancel={editModalCancel}
                    handleSubmit={handleEditClientsSubmit}
                    handleFailed={handleEditClientsFailed}
                    clients={clients}
                />
            }
            
            {
                showModal.deleteClients &&
                <ConfirmDeleteModal
                    visible={showModal.deleteClients}
                    onCancel={deleteModalCancel}
                    handleOk={handleDeleteClientsSubmit}
                    title="Delete client"
                    okText="Delete"
                    warningText="Are you sure to delete this client"
                    user={clients}
                />
            }

            <div className='justify-center p-3 py-7 nav-text'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                        <p>Detail Request Information</p>
                    </div>
                    {/* <div className='flex px-3 items-center'>
                        <p
                            className='px-2 cursor-pointer'
                            onClick={showEditModal}
                        >
                            Edit
                        </p>
                        <p className='text-red-800 px-2 cursor-pointer'
                            onClick={showDeleteModal}
                        >
                            Delete
                        </p>
                    </div> */}
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Client ID
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.clientID}
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    WasherID
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.washerID}
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Status
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.requestStatus ===1 ? "completed" : "Not Complated"}
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Is Read 
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.isRead ? "Yes" : "No"}
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Is Schedule
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.isScheduled ===1 ? "Yes" : "No"}
                                </td>
                            </tr>
                            {/* <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    City
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.location?.address?.city}
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Street Address
                                </th>
                                <td className="px-6 py-4">
                                    {clients?.data?.location?.address?.streetAddress}
                                </td>
                            </tr> */}
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Is Cash
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.paymentMethods?.isCash ? "Yes" : "No"}                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Is Payed
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.paymentMethods?.isPayed ? "Yes" : "No"}                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Item Cost
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.paymentMethods?.price?.itemCost}                 
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Tax
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.paymentMethods?.price?.tax }                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Tip
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.paymentMethods?.price?.tip }                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Time
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.scheduleDetail?.time }                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Addtional Service
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.services?.additional }                                
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Service Type
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.services?.serviceType?.message }                               
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                Vehicel
                                </th>
                                <td className="px-6 py-4">
                                {clients?.data?.address?.street}
                               </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Created At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(clients?.data?.createdAt).format('l')
                                    }
                                </td>
                            </tr>

                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    Update At
                                </th>
                                <td className="px-6 py-4">
                                    {
                                        moment(clients?.data?.updatedAt).format('l')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='flex flex-row md:w-full items-center mt-3 flex-wrap'>
                        {
                            isLoading || isFetching ?
                            (<div className='flex'>
                                <Spin indicator={antIcon} />
                            </div>) :
                            (
                            cardInfo?.map(cardInfo => {
                                return (
                                    <div
                                        className='flex flex-col status_container mr-5 items-center mt-3 justify-center pt-5'>
                                        <h1 className='dashboard_header'>
                                            {cardInfo?.type}
                                        </h1>
                                        <p className='dashboard_items_value'>
                                            {cardInfo?.cvc}
                                        </p>
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


