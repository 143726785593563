
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper } from '../providesTagsHelper'


const tagType = "WASHER"

export const washerUserApi = createApi({
    reducerPath: "washerUserApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAllWasher: builder.query({
            query: () => '/user/allWashers',
            providesTags: (result) => providesTagsHelper(result, tagType,"WASHER"),
        }),
        getWasherByID: builder.query({
            query: (washerId) => `user/profile/${washerId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"WASHER"),
        }),
        addWasher: builder.mutation({
            query: washerData => ({
                url: 'auth/signup',
                method: 'POST',
                body: washerData
            }),
            invalidatesTags: [{ type: tagType }],
        }),
        updateProfile: builder.mutation({
            query: body => ({
                url:`user/updateProfile/${body?.email}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'WASHER' },'WASHER', 'CLIENT', 'ADMIN'],
        }),
        deleteUser: builder.mutation({
            query: clientsId => ({
                url: `user/removeUser/${clientsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }, { type: 'WASHER' }],
        }),
        addVerifyUser: builder.mutation({
            query: userData => ({
                url: 'auth/verify',
                method: 'POST',
                body: userData
            }),
            invalidatesTags: [{ type: tagType } ,'WASHER'],
        }),



    })
})


export const { 
    useGetAllWasherQuery, 
    useGetWasherByIDQuery, 
    useAddWasherMutation,
    useUpdateProfileMutation,
    useDeleteUserMutation,
    useAddVerifyUserMutation
 } = washerUserApi



