
import React, { useEffect, useState } from 'react'


import { Modal, Button, Form, Input, message, Select } from 'antd'

import {useAddAdminLogMutation} from '../../../services/admin/admin.service'
import { toast, ToastContainer } from 'react-toastify'


export const AddAdminLog = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();

    const [loading,setLoading]=useState(false)
    const [setLoadingManageUser]=useState(false)
    const [loadingReportView,setLoadingReportView]=useState(false)
    
    const [addAdmin, {  isLoading, isSuccess, isError, error }] = useAddAdminLogMutation()

    if (isSuccess) {
            toast.success("admin Added successfully!")
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
    if (isError) {
        toast.error(error?.data.data)
        setTimeout(() => {
        }, 3000);
    }


    const handleSubmit = async (values) => {
        try {
                const AdminLog={
                    logType: values.logType,
                    title: values.title,
                    description:{
                        note: values.note
                    }
                }

                const body = {
                    ...AdminLog
                }

                await addAdmin(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

   
    const userRoleOptions = (values) =>{

        if(values==='admin'){
            setLoading(false)
        }
        if(values==='super_admin'){
            setLoading(true)
        }
    }

    const accessLevelOption = (values) =>{

        if(values==='manageUser'){
            setLoadingManageUser(true)
            setLoadingReportView(false)
        }
        else if(values==='viewReport'){
            setLoadingManageUser(false)
            setLoadingReportView(true)
        }
        else {
            setLoadingManageUser(false)
            setLoadingReportView(false)
        }
    }

    useEffect(()=>{
        userRoleOptions()
    })


    return (
        <>
            <ToastContainer />

            <Modal
                visible={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style '
                confirmLoading={isLoading}
            >

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >
                    
                    <Form.Item label="User Role" name="userRole"  >
                        <Select onChange={userRoleOptions}>
                            <Select.Option value="admin">Admin</Select.Option>
                            <Select.Option value="super_admin">Super Admin</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Type" name="title" >
                        <Select disabled={loading} onChange={accessLevelOption} >
                            <Select.Option value="Manage User">Manage User</Select.Option>
                            <Select.Option value="Manage Setting">Manage Setting </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Manage User" name="logType" >
                        <Select disabled={loading || loadingReportView} >
                            <Select.Option value="Edit">Edit User</Select.Option>
                            <Select.Option value="Delete">Delete User</Select.Option>
                            <Select.Option value="Create">Create User</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Note"
                        name="note"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First Name!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
