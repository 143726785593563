
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from '../../common'
import { token } from '../auth-header'
import { providesTagsHelper } from '../providesTagsHelper'

const tagType = "SUBSCRPTION"

export const subscrptionUserApi = createApi({
    reducerPath: "subscrptionUserApi",
    tagTypes:[tagType],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getToken }) => {
            const _token = token()
            if (_token) {
                headers.set('Authorization', `Bearer ${_token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAllSubscrption: builder.query({
            query: () => 'membership',
            providesTags: (result) => providesTagsHelper(result, tagType,"CLIENT"),
        }),
        getSubscrptionByID: builder.query({
            query: (SubscriptionId) => `membership/${SubscriptionId}`,
            providesTags: (result) => providesTagsHelper(result, tagType,"CLIENT"),
        }),

        addSubscrption: builder.mutation({
            query: clientData => ({
                url: 'membership',
                method: 'POST',
                body: clientData
            }),
            invalidatesTags: [{ type: tagType },'CLIENT'],
        }),
        deleteSubscrption: builder.mutation({
            query: SubscriptionId => ({
                url: `membership/${SubscriptionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: tagType }, { type: 'CLIENT' }],
        }),
        updateSubscrption: builder.mutation({
            query: body => ({
                url: `membership/upgrade/${body.clientsId}`,
                method: 'PATCH',
                body: {...body.values}
            }),
            invalidatesTags: [{ type: 'CLIENT' },'CLIENT', 'WASHER', 'ADMIN'],
        }),
    })
})


export const { 
    useGetAllSubscrptionQuery, 
    useGetSubscrptionByIDQuery,
    useAddSubscrptionMutation,
    useDeleteSubscrptionMutation,
    useUpdateSubscrptionMutation,
 } = subscrptionUserApi



