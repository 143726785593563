
import React, { useState } from 'react'


import { Modal, Button, Form, Input, message } from 'antd'

import { useAddAdminLogMutation, useGetAllByRoleQuery } from '../../services/admin/admin.service' 
import { toast } from 'react-toastify'


export const AddNotification = ({ visible, handleCancel }) => {

    const [form] = Form.useForm();

    const [ role , setRole ]= useState("CLIENT");
    const [ userData, setUserData ]=useState("");

    const response = useGetAllByRoleQuery(role)
    const { data } = response
    const _data = data?.data?.Items

    const [addAdmin, {  isLoading, isSuccess, isError, error }] = useAddAdminLogMutation()

    if (isSuccess) {
            toast.success("message sent successfully!",{
                toastId: "success"
            })
            setTimeout(() => {
                handleCancel()
            }, 3000);
        }
    if (isError) {
        toast.error(error?.data.data)
        setTimeout(() => {
        }, 3000);
    }


    const handleSubmit = async (values) => {
        try {
                const AdminLog={
                    to: userData,
                    data:{
                        withSome: values.withSome
                    },
                    body: values.body,
                    title: values.title
                }

                const body = {
                    ...AdminLog
                }

                await addAdmin(body)

        } catch (error) {
            return error
        }
    };


    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <>
            <Modal
                open={visible}
                title="Add admin"
                onOk={form.submit}
                onCancel={handleCancel}
                footer={null}
                className='xl:admin_modal_style lg:admin_modal_style md:admin_modal_style '
                confirmLoading={isLoading}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='flex flex-col py-5 w-full'
                >                    
                    <div className=' '>
                        Role
                        <div className="relative w-full lg:max-w-sm border-white justify-center">
                            <select  value={role} onChange={(event) => setRole(event.target.value)} className="w-full p-2.5  bg-white  rounded-md ">
                                <option value="CLIENT">Clients </option>
                                <option value="WASHER">Washers </option>
                                <option value="ADMIN">Admin</option>
                                <option value="SUPERADMIN">Super Admin</option>
                                <option value="CLIENTSERVICE">Client service</option>
                                <option value="WASHERSERVICE">Washer service</option>
                            </select>
                        </div>
                    </div>
                    <div className="relative w-full lg:max-w-sm border-white justify-center">
                        <p>Detail User</p>
                        <select  value={userData} onChange={(event) => setUserData(event.target.value)} className="w-full p-2.5  bg-white  rounded-md ">
                            {
                                _data?.map( (data,index) => 
                                <option key={index} value={data?.id}>{data?.fullName}</option> )
                            }
                        </select>
                    </div>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your title',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Body"
                        name="body"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your body!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item
                        label="Data"
                        name="withSome"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your data!',
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                            htmlType="submit"
                            className='ant-btn-primary-login bg-green-600'
                            loading={isLoading}
                        >
                            Notify
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )
}
