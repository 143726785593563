import React, { useState } from 'react'
import { Menu, Dropdown } from 'antd'
import { CgDetailsMore } from 'react-icons/cg'
import { AiOutlineMore } from 'react-icons/ai'
import { userTypes } from '../../common/UserType'
import { useUpdateProfileMutation } from '../../services/washer/washer.service' 
import { VerifyWasher } from '../verify/VerifyWasher'

export const WasherOptions = ({ record, userType }) => {
    
    const id = record.id
    const [visible, setVisible]=useState(false)

    const { updateProfile } = useUpdateProfileMutation()

    const handleActivate = () => {
        const email = id
        const activate={
            isVerified: true
        }
        updateProfile({
            email,
            values:activate
        })
    }

    const handleDeActivate = () => {
        const email = id
        const deactivate={
            isVerified: false
        }
        updateProfile({
            email,
            values:deactivate
        })

    }

    const handleVerify = () =>{
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
    }

    const menu = (
        <Menu style={{ width: 140 }}>
            {
                userType === userTypes.WASHER &&
                <div>
                    <Menu.Item key="1">
                        <div className='flex items-center' >
                            {
                                record?.isVerified ?
                                <>
                                <div className='flex flex-row'>
                                    <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                        <CgDetailsMore />
                                        <button onClick={handleDeActivate}>
                                            Deactivate
                                        </button>
                                    </div>
                                </div>
                                </>
                                :
                                <div className='flex flex-col'>
                                    <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                        <CgDetailsMore />
                                        <button onClick={handleActivate}>
                                            Activate
                                        </button>
                                    </div>
                                    <div className='px-4 py-2 shadow-md hover:shadow-lg flex flex-row'>
                                        <CgDetailsMore />
                                        <button onClick={handleVerify}>
                                            Verify
                                        </button>
                                    </div>
                                </div>
                                }
                        </div>
                    </Menu.Item>
                </div>
            }

            <Menu.Divider />
        </Menu>
    )

    return (

        <div className='flex flex-col'>
            {
                visible && <VerifyWasher visible={visible} handleCancel={handleCancel} record={record}/>
            }
            <div className='flex flex-col cursor-pointer w-full items-center justify-center'>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" key={id}>
                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <AiOutlineMore className='w-7 h-7' />
                    </span>
                </Dropdown>
            </div>
        </div>
    )
}
